<template>
  <b-container fluid>
    <b-overlay :show="loading">
      <b-row>
        <b-col sm="12">
          <b-card no-body>
            <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
              <b-form  @submit.prevent="handleSubmit(languageSubmit)" @reset.prevent="reset" >
                <iq-card>
                  <template v-slot:headerTitle>
                    <div class="text-center">
                      <h5 class="card-title">{{ $t('admission_form.lang_skill_info') }}</h5>
                    </div>
                  </template>
                  <template v-slot:body>
                    <b-row>
                      <b-col sm="4">
                        <ValidationProvider name="English Reading Skill" vid="english_reading_skill" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="english_reading_skill "
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                  {{$t('admission_form.english_reading_skill')}}
                                </template>
                                <b-form-select
                                plain
                                v-model="language_info.english_reading_skill"
                                id="english_reading_skill"
                                :options="englishSkillList"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col sm="4">
                        <ValidationProvider name="English Writing Skill" vid="english_writing_skill" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="english_writing_skill "
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                    {{ $t('admission_form.english_writing_skill') }}
                                </template>
                                <b-form-select
                                plain
                                v-model="language_info.english_writing_skill"
                                id="english_writing_skill"
                                :options="englishSkillList"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col sm="4">
                        <ValidationProvider name="English Speaking Skill" vid="english_speaking_skill" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="english_speaking_skill "
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                  {{ $t('admission_form.english_speaking_skill') }}
                                </template>
                                <b-form-select
                                plain
                                v-model="language_info.english_speaking_skill"
                                id="english_speaking_skill"
                                :options="englishSkillList"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                      </b-col>
                    </b-row>
                  </template>
                </iq-card>
              </b-form>
            </ValidationObserver>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { languageStore } from '../../../api/routes'

export default {
  props: ['language_info'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
      loading: false,
      englishSkill: [
        {
          id: 1,
          name: 'Very Good',
          name_bn: 'খুব ভালো'
        },
        {
          id: 2,
          name: 'Good',
          name_bn: 'ভাল'
        },
        {
          id: 3,
          name: 'Fair',
          name_bn: 'চলনসই'
        }
      ]
    }
  },
  mounted () {
    core.index()
  },
  watch: {
  },
  computed: {
    englishSkillList: function () {
        return this.englishSkill.map((obj, key) => {
            if (this.$i18n.locale === 'bn') {
                return { value: obj.id, text: obj.name_bn }
            } else {
                return { value: obj.id, text: obj.name }
            }
        })
    }
  },
  methods: {
    async languageSubmit () {
      this.loading = true
      let result = null
      const generalInfoID = this.$store.state.ExternalUserIrrigation.incentiveGrant.applicantDetails.id
      const formData = Object.assign({}, this.language_info, { app_general_info_id: generalInfoID })
      result = await RestApi.postData(incentiveGrantServiceBaseUrl, languageStore, formData)

      this.loading = false
      if (result.success) {
        this.$toast.success({
          title: 'Success',
          message: this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        return result
      } else {
        this.$toast.error({
          title: 'Error',
          message: this.$t('globalTrans.form_error_msg'),
          color: '#D6E09B'
        })
        this.$refs.form.setErrors(result.errors)
      }
    }
  }
}
</script>

<template>
  <b-container fluid>
    <b-row class="admissionFormMain">
      <b-col md="12">
        <!-- <b-card v-if="loading" no-body style="margin-bottom: 20px;border-radius: 10px;box-shadow: 0 0 3px rgb(120 146 141 / 50%);">
          <b-overlay :show="loading">
            <h2 class="text-center">{{ $t('globalTrans.loading') }}</h2>
          </b-overlay>
        </b-card> -->
        <b-card no-body style="margin-bottom: 20px;border-radius: 10px;box-shadow: 0 0 3px rgb(120 146 141 / 50%);">
          <b-overlay :show="!tabFormLoading">
            <form-wizard v-if="tabFormLoading" shape="circle" color="#66CC99" title="" subtitle="">
              <tab-content class="text-small" :title="$t('admission_form.gen_inf')" icon="ri-home-4-line" :before-change="tabSubmitGeneral">
                <General :main_form="main_form" ref="social"/>
              </tab-content>
              <tab-content :title="$t('admission_form.add_info')" icon="ri-home-4-line" :before-change="tabSubmitAddressInfo">
                <AddressInfo :address_info="main_form.address_info" ref="address"/>
              </tab-content>
              <tab-content :title="$t('admission_form.lang_skill_info')" icon="ri-home-4-line" :before-change="tabSubmitLanguageInfo">
                <Language :language_info="main_form.language_info" ref="language"/>
              </tab-content>
              <tab-content :title="$t('admission_form.aca_record_info')" icon="ri-home-4-line" :before-change="tabAcademic">
                <Academic :academic_info="main_form.academic_info" ref="academic"/>
              </tab-content>
              <tab-content :title="$t('admission_form.training_details')" icon="ri-home-4-line" :before-change="tabTraining">
                <Training :training_info="main_form.training_info" ref="training"/>
              </tab-content>
              <tab-content :title="$t('admission_form.seminar')" icon="ri-home-4-line" :before-change="tabSubmitSeminar">
                <Seminar :seminar_info="main_form.seminar_info" ref="seminar"/>
              </tab-content>
              <tab-content :title="$t('admission_form.qualification')" icon="ri-home-4-line" :before-change="tabQualification">
                <Qualification :other_info="main_form.other_info" ref="qualification"/>
              </tab-content>
              <tab-content :title="$t('admission_form.annexure_1')" icon="ri-home-4-line" :before-change="tabSubmitAnnexure1Info">
                <AnnexureI :annexure_one_info="main_form.annexure_one_info" ref="annexure1"/>
              </tab-content>
              <tab-content :title="$t('admission_form.annexure_2_emp_record')" icon="ri-home-4-line" :before-change="tabAnnexure2EmploymentRecord">
                <Annexure2EmployeeRecord :employee_record_info="main_form.employee_record_info" :customOrgList="tmpOrgList" ref="employee_record" />
              </tab-content>
              <tab-content :title="$t('admission_form.annexure_2_pub_info')" icon="ri-home-4-line" :before-change="tabAnnexure2PublicationInfo">
                <Annexure2Publication :publication_info="main_form.publication_info" ref="publication_info" />
              </tab-content>
              <tab-content :title="$t('admission_form.annexure_2_relevant_fact')" icon="ri-home-4-line" :before-change="tabAnnexure2RelevantFact">
                <Annexure2RelevantFact :relevant_info="main_form.relevant_info" ref="relevant_info"/>
              </tab-content>
              <tab-content :title="$t('admission_form.annexure_3_info')" icon="ri-home-4-line" :before-change="tabSubmitAnnexure3Info">
                <AnnexureIII :annexure_three_info="main_form.annexure_three_info" ref="annexure3"/>
              </tab-content>
              <!-- <button slot="finish" class="wizard-btn-custom" style="background-color: rgb(102, 204, 153); border-color: rgb(102, 204, 153); color: white;">{{ $t('globalTrans.submit') }}</button> -->
              <template slot="footer" slot-scope="props">
                <div class="wizard-footer-right">
                    <wizard-button class="btn btn-secondary rounded-pill" @click.native="props.prevTab(), step--"  v-if="props.activeTabIndex > 0" :style="props.fillButtonStyle">{{ $t('globalTrans.back') }}</wizard-button>
                    <wizard-button
                        type="submit"
                        v-if="!props.isLastStep"
                        @click.native="props.nextTab(), isNext = true"
                        class="btn btn-success ml-1 mr-1 rounded-pill"
                        title="Draft and go to next!"
                        :style="props.fillButtonStyle"
                    >{{ $t('globalTrans.next') }}
                    </wizard-button>
                    <wizard-button
                        v-else
                        slot="finish"
                        type="button"
                        class="btn btn-success ml-1 rounded-pill"
                        title="Submit!"
                        @click.native="finish()"
                    >
                        {{ $t('globalTrans.dakhil')}}
                    </wizard-button>
                </div>
            </template>
            </form-wizard>
          </b-overlay>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { FormWizard, TabContent } from 'vue-form-wizard'
import WizardButton from 'vue-form-wizard/src/components/WizardButton.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import General from './GeneralInfo.vue'
import AddressInfo from './Address.vue'
import Language from './Language.vue'
import Academic from './Academic.vue'
import Training from './Training.vue'
import Seminar from './Seminar.vue'
import Qualification from './Qualification.vue'
import AnnexureI from './Annexure1.vue'
import Annexure2EmployeeRecord from './Annexure2EmployeeRecord.vue'
import Annexure2Publication from './Annexure2Publication.vue'
import Annexure2RelevantFact from './Annexure2RelevantFact.vue'
import AnnexureIII from './Annexure3.vue'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { applicationDetailsShow, checkUniversityCourse } from '../../../api/routes'
import { mapGetters } from 'vuex'

export default {
  props: ['data'],
  components: {
    FormWizard,
    WizardButton,
    TabContent,
    General,
    AddressInfo,
    Language,
    Academic,
    Training,
    Seminar,
    Qualification,
    AnnexureI,
    Annexure2EmployeeRecord,
    Annexure2Publication,
    Annexure2RelevantFact,
    AnnexureIII
  },
  mounted () {
    core.index()
  },
  computed: {
    ...mapGetters({
      authUser: 'Auth/authUser'
    })
  },
  async created () {
    if (this.$route.query.circular_id) {
      this.$store.dispatch('ExternalUserIrrigation/setGrantApplicantDetails', {})
      this.checkProfileApp(this.$route.query.circular_id)
      this.main_form.circular_id = this.$route.query.circular_id
    }
    if (this.$route.query.id) {
      this.getEditData(this.$route.query.id)
    } else {
      this.tabFormLoading = true
    }
  },
  data () {
    return {
      tabFormLoading: false,
      main_form: {
        id: '',
        name: '',
        name_bn: '',
        father_name: '',
        father_name_bn: '',
        fiscal_year_id: 0,
        circular_id: 0,
        candidate_type: 0,
        can_org_type: 0,
        can_org_id: 0,
        field_study: '',
        field_study_bn: '',
        applicant_id: 0,
        mother_name: '',
        mother_name_bn: '',
        date_of_birth: '',
        age: '',
        place_of_birth: '',
        marital_status: 0,
        nid: '',
        mobile_no: '',
        email: '',
        designation: '',
        designation_bn: '',
        photo: null,
        signature: null,
        evaluation_year: '',
        evaluation_attachment: null,
        address_info: {
          area_type_id: '0',
          city_corporation_id: '0',
          pauroshoba_id: '0',
          ward_id: '0',
          country_id: 1,
          division_id: '0',
          district_id: '0',
          upazilla_id: '0',
          union_id: '0',
          village_name: '',
          village_name_bn: '',
          house_number: '',
          house_number_bn: '',
          same_as_present_address: 0,
          per_area_type_id: '0',
          per_city_corporation_id: '0',
          per_pauroshoba_id: '0',
          per_ward_id: '0',
          per_country_id: 1,
          per_division_id: '0',
          per_district_id: '0',
          per_upazilla_id: '0',
          per_union_id: '0',
          per_village_name: '',
          per_village_name_bn: '',
          per_house_number: '',
          per_house_number_bn: ''
        },
        language_info: {
          english_reading_skill: 0,
          english_writing_skill: 0,
          english_speaking_skill: 0
        },
        academic_info: [
          {
            education_level_id: 0,
            score_system_id: 0,
            class_or_grade_id: 0,
            degree_title: '',
            degree_title_bn: '',
            group: '',
            group_bn: '',
            institute: '',
            institute_bn: '',
            address: '',
            address_bn: '',
            year_from: '',
            year_to: '',
            passing_year: '',
            attachment: null
          }
        ],
        training_info: [
          {
            training_title: '',
            venue: '',
            venue_bn: '',
            from_date: '',
            to_date: '',
            training_type: '',
            batch: '',
            attachment: ''
          }
        ],
        seminar_info: [
          {
            seminar_name: '',
            seminar_name_bn: '',
            venue: '',
            venue_bn: '',
            from_date: '',
            to_date: ''
          }
        ],
        other_info: {
          qualification: '',
          qualification_bn: ''
        },
        annexure_one_info: {
          evidence: '',
          evidence_bn: '',
          membership: '',
          membership_bn: ''
        },
        employee_record_info: [
          {
            can_org_type: 2,
            org_id: 0,
            position: '',
            position_bn: '',
            posting: '',
            posting_bn: '',
            remark: '',
            remark_bn: '',
            start_date: '',
            end_date: ''
          }
        ],
        publication_info: [
          {
            publication_name: '',
            publication_name_bn: '',
            author_type: 0,
            publication_type: 0,
            attachment: null
          }
        ],
        relevant_info: {
          fact: '',
          fact_bn: ''
        },
        annexure_three_info: [
          {
            year: 0,
            type: 0,
            annxThreeAttachment: ''
          }
        ]
      },
      type: 1,
      tmpOrgList: []
    }
  },
  methods: {
    async checkProfileApp (circularId) {
      this.loading = true
      const params = Object.assign({}, { user_id: this.authUser.user_id, circular_id: circularId })
      const result = await RestApi.getData(incentiveGrantServiceBaseUrl, checkUniversityCourse, params)
      this.loading = false
      if (result.user.length < 1) {
        this.$router.push({ name: 'external_user.grant_panel.profile_update' })
      } else {
        this.main_form.name = result.user.name
        this.main_form.name_bn = result.user.name_bn
        this.main_form.email = result.user.email
        this.main_form.father_name = result.user.father_name
        this.main_form.father_name_bn = result.user.father_name_bn
        this.main_form.mother_name = result.user.mother_name
        this.main_form.mother_name_bn = result.user.mother_name_bn
        this.main_form.nid = result.user.nid_no
        this.main_form.mobile_no = result.user.mobile_no
      }

      if (result.slotBooking.length) {
        this.$toast.error({
          title: this.$t('globalTrans.error'),
          message: this.$t('dealer_panel.slot_booking_msg'),
          color: '#fff000'
        })
        this.$router.push('/grant-panel/application-form')
      } else if (result.circularWiseAppCheck.length) {
        this.$toast.error({
          title: this.$t('globalTrans.error'),
          message: this.$t('dealer_panel.already_apply'),
          color: '#fff000'
        })
        this.$router.push('/grant-panel/application-form')
      }
    },
    async getEditData (id) {
      const response = await RestApi.getData(incentiveGrantServiceBaseUrl, applicationDetailsShow + '/' + id)
      if (response.success) {
        this.main_form = response.data
        if (this.main_form.address_info === null) {
          this.main_form.address_info = Object.assign({},
            {
              area_type_id: '0',
              city_corporation_id: '0',
              pauroshoba_id: '0',
              ward_id: '0',
              country_id: 1,
              division_id: '0',
              district_id: '0',
              upazilla_id: '0',
              union_id: '0',
              village_name: '',
              village_name_bn: '',
              house_number: '',
              house_number_bn: '',
              same_as_present_address: 0,
              per_area_type_id: '0',
              per_city_corporation_id: '0',
              per_pauroshoba_id: '0',
              per_ward_id: '0',
              per_country_id: 1,
              per_division_id: '0',
              per_district_id: '0',
              per_upazilla_id: '0',
              per_union_id: '0',
              per_village_name: '',
              per_village_name_bn: '',
              per_house_number: '',
              per_house_number_bn: ''
            }
          )
        }
        if (this.main_form.language_info === null) {
          this.main_form.language_info = Object.assign({},
            {
              english_reading_skill: 0,
              english_writing_skill: 0,
              english_speaking_skill: 0
            }
          )
        }

        if (this.main_form.academic_info.length === 0) {
          this.main_form.academic_info = [
            {
              education_level_id: 0,
              score_system_id: 0,
              class_or_grade_id: 0,
              degree_title: '',
              degree_title_bn: '',
              group: '',
              group_bn: '',
              institute: '',
              institute_bn: '',
              address: '',
              address_bn: '',
              year_from: '',
              year_to: '',
              passing_year: '',
              attachment: null
            }
          ]
        }

        if (this.main_form.training_info.length === 0) {
          this.main_form.training_info = [
            {
              training_title: 0,
              venue: '',
              venue_bn: '',
              from_date: '',
              to_date: '',
              training_type: 0,
              batch: '',
              attachment: null
            }
          ]
        }

        if (this.main_form.seminar_info.length === 0) {
          this.main_form.seminar_info = [
            {
              seminar_name: '',
              seminar_name_bn: '',
              venue: '',
              venue_bn: '',
              from_date: '',
              to_date: ''
            }
          ]
        }

        if (this.main_form.other_info === null) {
          this.main_form.other_info = Object.assign({},
            {
              qualification: '',
              qualification_bn: ''
            }
          )
        }

        if (this.main_form.annexure_one_info === null) {
          this.main_form.annexure_one_info = Object.assign({},
            {
              evidence: '',
              evidence_bn: '',
              membership: '',
              membership_bn: ''
            }
          )
        }

        if (this.main_form.employee_record_info.length === 0) {
          this.main_form.employee_record_info = [
            {
              can_org_type: 2,
              org_id: 0,
              position: '',
              position_bn: '',
              posting: '',
              posting_bn: '',
              remark: '',
              remark_bn: '',
              start_date: '',
              end_date: ''
            }
          ]
        }

        if (this.main_form.publication_info.length === 0) {
          this.main_form.publication_info = [
            {
              publication_name: '',
              publication_name_bn: '',
              author_type: 0,
              publication_type: 0,
              attachment: null
            }
          ]
        }

        if (this.main_form.relevant_info === null) {
          this.main_form.relevant_info = Object.assign({},
            {
              fact: '',
              fact_bn: ''
            }
          )
        }

        if (this.main_form.annexure_three_info.length === 0) {
          this.main_form.annexure_three_info = [
            {
              year: 0,
              type: 0,
              annxThreeAttachment: ''
            }
          ]
        }
        this.tabFormLoading = true
      }
    },
    async tabSubmitGeneral () {
      // return true
      let result = null
      result = await this.$refs.social.generalSubmit()
      this.tmpOrgList = result.customOrgList
      if (result.success) {
        this.$store.dispatch('ExternalUserIrrigation/setGrantApplicantDetails', result.data)
        // this.type = 2
        return true
      }
    },
    async tabSubmitAddressInfo () {
      // return true
      let result = null
      result = await this.$refs.address.addressSubmit()
      if (result.success) {
        // this.type = 3
        return true
      }
    },
    async tabSubmitLanguageInfo () {
      // return true
      let result = null
      result = await this.$refs.language.languageSubmit()
      if (result.success) {
        // this.type = 4
        return true
      }
    },
    async tabAcademic () {
      // return true
      let result = null
      result = await this.$refs.academic.academicSubmit()
      if (result.success) {
        // this.type = 5
        return true
      }
    },
    async tabTraining () {
      // return true
      let result = null
      result = await this.$refs.training.trainingSubmit()
      if (result.success) {
        // this.type = 6
        return true
      }
    },
    async tabSubmitSeminar () {
      // return true
      let result = null
      result = await this.$refs.seminar.seminarSubmit()
      if (result.success) {
        // this.type = 7
        return true
      }
    },
    async tabQualification () {
      // return true
      let result = null
      result = await this.$refs.qualification.qualificationSubmit()
      if (result.success) {
        // this.type = 8
        return true
      }
    },
    async tabSubmitAnnexure1Info () {
      // return true
      let result = null
      result = await this.$refs.annexure1.annexure1Submit()
      if (result.success) {
        // this.type = 9
        return true
      }
    },
    async tabAnnexure2EmploymentRecord () {
      // return true
      let result = null
      result = await this.$refs.employee_record.annexure2EmploymentRecordSubmit()
      if (result.success) {
        // this.type = 10
        return true
      }
    },
    async tabAnnexure2PublicationInfo () {
      // return true
      let result = null
      result = await this.$refs.publication_info.annexure2PublicationSubmit()
      if (result.success) {
        // this.type = 11
        return true
      }
    },
    async tabAnnexure2RelevantFact () {
      // return true
      let result = null
      result = await this.$refs.relevant_info.annexure2RelevantFactSubmit()
      if (result.success) {
        // this.type = 12
        return true
      }
    },
    async tabSubmitAnnexure3Info () {
      let result = null
      result = await this.$refs.annexure3.annexure3Submit()
      if (result.success) {
        this.$router.push({ path: '/grant-panel/application-form' })
      }
    },
    finish () {
      this.$store.dispatch('ExternalUserIrrigation/setGrantApplicantDetails', {})
      this.$router.push({ path: '/grant-panel/application-form' })
    }
  }
}
</script>

<style lang="scss">
  .admissionFormMain{
    .wizard-icon-circle {
      width: 30px !important;
      height: 30px !important;
      font-size: 10px !important;
    }
  }
</style>

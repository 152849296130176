<template>
    <b-container fluid>
        <b-overlay :show="loading">
            <b-row>
                <b-col sm="12">
                    <b-card no-body>
                    <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                        <b-form  @submit.prevent="handleSubmit(addressSubmit)" @reset.prevent="reset" >
                            <iq-card>
                                <template v-slot:headerTitle>
                                    <div class="text-center">
                                        <h5 class="card-title">{{ $t('admission_form.add_info') }}</h5>
                                    </div>
                                </template>
                                <template v-slot:body>
                                    <b-row>
                                        <b-col>
                                            <h5 class="ex_form_title"> {{ $t('educational_management.present_business_address') }}</h5>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col sm="4">
                                            <ValidationProvider name="Area Type" vid="area_type_id" rules="required|min_value:1">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="area_type_id "
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{$t('org_pro.area_type')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                    plain
                                                    v-model="address_info.area_type_id"
                                                    id="area_type_id"
                                                    :options="areaTypeList"
                                                    @change="getAreaTypeData(address_info.area_type_id)"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="4">
                                            <ValidationProvider name="Division" vid="division_id" rules="required|min_value:1">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="division_id"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <template v-slot:label>
                                                    {{ $t('org_pro_division.division')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                        plain
                                                        v-model="address_info.division_id"
                                                        :options="divisionList"
                                                        id="division_id"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="4">
                                            <ValidationProvider name="District" vid="district_id" rules="required|min_value:1">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="district_id"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                    {{ $t('org_pro_district.district')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                    plain
                                                    v-model="address_info.district_id"
                                                    :options="districtList"
                                                    id="district_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="4" v-show="PauroshobaItemShow || UnionItemShow">
                                            <ValidationProvider name="Upazila" vid="upazila_id">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="upazila_id"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                    {{ $t('org_pro_upazilla.upazilla')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                    plain
                                                    v-model="address_info.upazilla_id"
                                                    :options="upazilaList"
                                                    id="upazila_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="4" v-show="UnionItemShow">
                                            <ValidationProvider name="Union" vid="union_id">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="union_id"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                    {{ $t('org_pro_union.union')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                    plain
                                                    v-model="address_info.union_id"
                                                    :options="unionList"
                                                    id="union_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="4" v-show="CityCorpItemShow">
                                            <ValidationProvider name="City Corporation" vid="city_corporation_id">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="city_corporation_id"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{ $t('org_pro.city_corporation') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                    plain
                                                    v-model="address_info.city_corporation_id"
                                                    :options="cityCorporationList"
                                                    id="city_corporation_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="4" v-show="PauroshobaItemShow">
                                            <ValidationProvider name="Pauroshoba" vid="pauroshoba_id">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="pauroshoba_id"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{ $t('org_pro.pauroshoba') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                    plain
                                                    v-model="address_info.pauroshoba_id"
                                                    :options="pauroshobaList"
                                                    id="pauroshoba_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="4" v-show="ItemShow">
                                            <ValidationProvider name="Ward" vid="ward_id">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="ward_id"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{ $t('org_pro.ward') }}
                                                    </template>
                                                    <b-form-select
                                                    plain
                                                    v-model="address_info.ward_id"
                                                    :options="wardList"
                                                    id="ward_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="4">
                                            <ValidationProvider name="Village Name (En)" vid='village_name' rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="village_name"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <template v-slot:label>
                                                    {{ $t('globalTrans.village_en')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                    id="village_name"
                                                    v-model="address_info.village_name"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="4">
                                            <ValidationProvider name="Village Name (Bn)" vid='village_name_bn' rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="village_name_bn"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <template v-slot:label>
                                                        {{ $t('globalTrans.village_bn')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                    id="village_name_bn"
                                                    v-model="address_info.village_name_bn"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="4">
                                            <ValidationProvider name="House Number (En)" vid='house_number' rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="house_number"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <template v-slot:label>
                                                    {{ $t('admission_form.house_number_en')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                        id="house_number"
                                                        v-model="address_info.house_number"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="4">
                                            <ValidationProvider name="House Number (Bn)" vid='house_number_bn' rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="house_number_bn"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{ $t('admission_form.house_number_bn')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                    id="house_number_bn"
                                                    v-model="address_info.house_number_bn"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col>
                                            <h5 class="ex_form_title"> {{ $t('educational_management.permanent_address') }} </h5>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col lg="6" sm="12">
                                            <ValidationProvider>
                                                <b-form-group
                                                    class="row"
                                                    :label="$t('educational_management.present_business_address')"
                                                    label-cols-sm="4"
                                                    label-for="present_address"
                                                    slot-scope="{ errors }"
                                                >
                                                <div>
                                                    <b-form-checkbox
                                                        class="mt-2"
                                                        id="address_info.same_as_present_address"
                                                        v-model="address_info.same_as_present_address"
                                                        :value="1"
                                                        :unchecked-value="0"
                                                    >
                                                        {{$t('admission_form.same')}}
                                                    </b-form-checkbox>
                                                </div>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col sm="4">
                                            <ValidationProvider name="Area Type" vid="per_area_type_id" rules="required|min_value:1">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="per_area_type_id "
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <template v-slot:label>
                                                        {{$t('org_pro.area_type')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                        plain
                                                        v-model="address_info.per_area_type_id"
                                                        id="per_area_type_id"
                                                        :options="areaTypeList"
                                                        @change="getAreaTypePerData(address_info.per_area_type_id)"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="4">
                                            <ValidationProvider name="Division" vid="per_division_id" rules="required|min_value:1">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="per_division_id"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                    {{ $t('org_pro_division.division')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                    plain
                                                    v-model="address_info.per_division_id"
                                                    :options="divisionPerList"
                                                    id="per_division_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="4">
                                            <ValidationProvider name="District" vid="per_district_id" rules="required|min_value:1">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="per_district_id"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                    {{ $t('org_pro_district.district')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                    plain
                                                    v-model="address_info.per_district_id"
                                                    :options="districtPerList"
                                                    id="per_district_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="4" v-show="PauroshobaItemPerShow || UnionItemPerShow">
                                            <ValidationProvider name="Upazila" vid="per_upazila_id">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="per_upazila_id"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                    {{ $t('org_pro_upazilla.upazilla')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                    plain
                                                    v-model="address_info.per_upazilla_id"
                                                    :options="upazilaPerList"
                                                    id="per_upazila_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="4" v-show="UnionItemPerShow">
                                            <ValidationProvider name="Union" vid="per_union_id">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="per_union_id"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                    {{ $t('org_pro_union.union')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                    plain
                                                    v-model="address_info.per_union_id"
                                                    :options="unionPerList"
                                                    id="per_union_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="4" v-show="CityCorpItemPerShow">
                                            <ValidationProvider name="City Corporation" vid="per_city_corporation_id">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="per_city_corporation_id"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{ $t('org_pro.city_corporation') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                    plain
                                                    v-model="address_info.per_city_corporation_id"
                                                    :options="cityCorporationPerList"
                                                    id="per_city_corporation_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="4" v-show="PauroshobaItemPerShow">
                                            <ValidationProvider name="Pauroshoba" vid="per_pauroshoba_id">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="per_pauroshoba_id"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{ $t('org_pro.pauroshoba') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                    plain
                                                    v-model="address_info.per_pauroshoba_id"
                                                    :options="pauroshobaPerList"
                                                    id="per_pauroshoba_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="4" v-show="ItemShowPer">
                                            <ValidationProvider name="Ward" vid="per_ward_id">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="per_ward_id"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{ $t('org_pro.ward') }}
                                                    </template>
                                                    <b-form-select
                                                    plain
                                                    v-model="address_info.per_ward_id"
                                                    :options="wardPerList"
                                                    id="ward_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="4">
                                            <ValidationProvider name="Village Name (En)" vid='per_village_name' rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="per_village_name"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <template v-slot:label>
                                                    {{ $t('globalTrans.village_en')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                    id="per_village_name"
                                                    v-model="address_info.per_village_name"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="4">
                                            <ValidationProvider name="Village Name (Bn)" vid='per_village_name_bn' rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="per_village_name_bn"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{ $t('globalTrans.village_bn')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                    id="per_village_name_bn"
                                                    v-model="address_info.per_village_name_bn"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="4">
                                            <ValidationProvider name="House Number (En)" vid='per_house_number' rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="per_village_name"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <template v-slot:label>
                                                    {{ $t('admission_form.house_number_en')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                    id="per_house_number"
                                                    v-model="address_info.per_house_number"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="4">
                                            <ValidationProvider name="House Number (Bn)" vid='per_house_number_bn' rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="per_house_number_bn"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{ $t('admission_form.house_number_bn')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                    id="per_house_number_bn"
                                                    v-model="address_info.per_house_number_bn"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                    </b-row>
                                </template>
                            </iq-card>
                        </b-form>
                    </ValidationObserver>
                    </b-card>
                </b-col>
            </b-row>
        </b-overlay>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { addressStore } from '../../../api/routes'

export default {
    props: ['address_info'],
    components: {
        ValidationObserver,
        ValidationProvider
    },
    data () {
        return {
            loading: false,
            showIdType: 0,
            ItemShow: false,
            ItemShowPer: false,
            CityCorpItemShow: false,
            CityCorpItemPerShow: false,
            PauroshobaItemShow: false,
            PauroshobaItemPerShow: false,
            UnionItemShow: false,
            UnionItemPerShow: false,
            same_as_present_address: 0,
            districtList: [],
            upazilaList: [],
            unionList: [],
            cityCorporationList: [],
            pauroshobaList: [],
            wardList: [],
            districtPerList: [],
            upazilaPerList: [],
            unionPerList: [],
            cityCorporationPerList: [],
            pauroshobaPerList: [],
            wardPerList: [],
            areaTypeList: [
                { value: 1, text: this.$i18n.locale === 'bn' ? 'সিটি কর্পোরেশন' : 'City Corpoation' },
                { value: 2, text: this.$i18n.locale === 'bn' ? 'পৌরসভা' : 'Pauroshoba' },
                { value: 3, text: this.$i18n.locale === 'bn' ? 'ইউনিয়ন' : 'Union' }
            ]
        }
    },
    created () {
        if (this.address_info !== null) {
            this.getAreaTypeData(this.address_info.area_type_id)
            this.getAreaTypeData(this.address_info.per_area_type_id)
            this.districtList = this.getDistrictList(this.address_info.division_id)
            this.districtPerList = this.getDistrictList(this.address_info.per_division_id)
            this.upazilaList = this.getUpazilaList(this.address_info.district_id)
            this.upazilaPerList = this.getUpazilaList(this.address_info.per_district_id)
            this.ItemShowPer = true
            if (this.address_info.per_area_type_id === 3) {
                this.upazilaList = this.getUpazilaList(this.address_info.district_id)
                this.upazilaPerList = this.getUpazilaList(this.address_info.per_district_id)
                this.unionList = this.getUnionList(this.address_info.upazilla_id)
                this.unionPerList = this.getUnionList(this.address_info.per_upazilla_id)
                this.wardList = this.getWardListByUnion(this.address_info.union_id)
                this.wardPerList = this.getWardListByUnion(this.address_info.per_union_id)
                this.CityCorpItemPerShow = false
                this.PauroshobaItemPerShow = false
                this.UnionItemPerShow = true
            } else if (this.address_info.per_area_type_id === 2) {
                this.pauroshobaList = this.getPauroshobaList(this.address_info.upazilla_id)
                this.pauroshobaPerList = this.getPauroshobaList(this.address_info.per_upazilla_id)
                this.CityCorpItemPerShow = false
                this.PauroshobaItemPerShow = true
                this.UnionItemPerShow = false
            } else {
                this.cityCorporationList = this.getCityCorporationList(this.address_info.district_id)
                this.cityCorporationPerList = this.getCityCorporationList(this.address_info.per_district_id)
                this.wardList = this.getWardListByCityCorportaion(this.address_info.city_corporation_id)
                this.wardPerList = this.getWardListByCityCorportaion(this.address_info.per_city_corporation_id)
                this.CityCorpItemPerShow = true
                this.PauroshobaItemPerShow = false
                this.UnionItemPerShow = false
            }
        }
    },
    mounted () {
        core.index()
    },
    watch: {
        'address_info.division_id': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.districtList = this.getDistrictList(newVal)
            }
        },
        'address_info.per_division_id': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.districtPerList = this.getDistrictList(newVal)
            }
        },
        'address_info.district_id': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.cityCorporationList = this.getCityCorporationList(newVal)
                this.upazilaList = this.getUpazilaList(newVal)
            }
        },
        'address_info.per_district_id': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.cityCorporationPerList = this.getCityCorporationList(newVal)
                this.upazilaPerList = this.getUpazilaList(newVal)
            }
        },
        'address_info.upazilla_id': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.unionList = this.getUnionList(newVal)
                this.pauroshobaList = this.getPauroshobaList(newVal)
            }
        },
        'address_info.per_upazilla_id': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.unionPerList = this.getUnionList(newVal)
                this.pauroshobaPerList = this.getPauroshobaList(newVal)
            }
        },
        'address_info.city_corporation_id': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.wardList = this.getWardListByCityCorportaion(newVal)
            }
        },
        'address_info.per_city_corporation_id': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.wardPerList = this.getWardListByCityCorportaion(newVal)
            }
        },
        'address_info.union_id': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.wardList = this.getWardListByUnion(newVal)
            }
        },
        'address_info.per_union_id': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.wardPerList = this.getWardListByUnion(newVal)
            }
        },
        'address_info.same_as_present_address': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.address_info.per_area_type_id = this.address_info.area_type_id
                this.address_info.per_city_corporation_id = this.address_info.city_corporation_id
                this.address_info.per_pauroshoba_id = this.address_info.pauroshoba_id
                this.address_info.per_ward_id = this.address_info.ward_id
                this.address_info.per_division_id = this.address_info.division_id
                this.address_info.per_district_id = this.address_info.district_id
                this.address_info.per_upazilla_id = this.address_info.upazilla_id
                this.address_info.per_union_id = this.address_info.union_id
                this.address_info.per_village_name = this.address_info.village_name
                this.address_info.per_village_name_bn = this.address_info.village_name_bn
                this.address_info.per_house_number = this.address_info.house_number
                this.address_info.per_house_number_bn = this.address_info.house_number_bn
                this.ItemShowPer = true
                if (this.address_info.per_area_type_id === 1) {
                    this.CityCorpItemPerShow = true
                    this.PauroshobaItemPerShow = false
                    this.UnionItemPerShow = false
                } else if (this.address_info.per_area_type_id === 2) {
                    this.CityCorpItemPerShow = false
                    this.PauroshobaItemPerShow = true
                    this.UnionItemPerShow = false
                } else if (this.address_info.per_area_type_id === 3) {
                    this.CityCorpItemPerShow = false
                    this.PauroshobaItemPerShow = false
                    this.UnionItemPerShow = true
                }
            }
        }
    },
    computed: {
        divisionList: function () {
            return this.$store.state.ExternalUserIrrigation.commonObj.divisionList.filter(item => item.status === 0)
        },
        divisionPerList: function () {
            return this.$store.state.ExternalUserIrrigation.commonObj.divisionList.filter(item => item.status === 0)
        }
    },
    methods: {
        getAreaTypeData (typeId) {
            this.ItemShow = true
            if (typeId === 1) {
                this.CityCorpItemShow = true
                this.PauroshobaItemShow = false
                this.UnionItemShow = false
            } else if (typeId === 2) {
                this.CityCorpItemShow = false
                this.PauroshobaItemShow = true
                this.UnionItemShow = false
            } else if (typeId === 3) {
                this.CityCorpItemShow = false
                this.PauroshobaItemShow = false
                this.UnionItemShow = true
            }
        },
        getAreaTypePerData (typeId2) {
            this.ItemShowPer = true
            if (typeId2 === 1) {
                this.CityCorpItemPerShow = true
                this.PauroshobaItemPerShow = false
                this.UnionItemPerShow = false
            } else if (typeId2 === 2) {
                this.CityCorpItemPerShow = false
                this.PauroshobaItemPerShow = true
                this.UnionItemPerShow = false
            } else if (typeId2 === 3) {
                this.CityCorpItemPerShow = false
                this.PauroshobaItemPerShow = false
                this.UnionItemPerShow = true
            }
        },
        async addressSubmit () {
            this.loading = true
            let result = null
            const generalInfoID = this.$store.state.ExternalUserIrrigation.incentiveGrant.applicantDetails.id
            const formData = Object.assign({}, this.address_info, { app_general_info_id: generalInfoID })
            result = await RestApi.postData(incentiveGrantServiceBaseUrl, addressStore, formData)
            this.loading = false
            if (result.success) {
                this.$toast.success({
                    title: 'Success',
                    message: this.$t('globalTrans.save_msg'),
                    color: '#D6E09B'
                })
                return result
            } else {
                this.$toast.error({
                    title: 'Error',
                    message: this.$t('globalTrans.form_error_msg'),
                    color: '#D6E09B'
                })
                this.$refs.form.setErrors(result.errors)
            }
        },
        getDistrictList (divisionId = null) {
            const districtList = this.$store.state.ExternalUserIrrigation.commonObj.districtList.filter(item => item.status === 0)

            if (divisionId) {
                return districtList.filter(district => district.division_id === divisionId)
            }

            return districtList
        },
        getUpazilaList (districtId = null) {
            const upazilaList = this.$store.state.ExternalUserIrrigation.commonObj.upazilaList.filter(item => item.status === 0)

            if (districtId) {
                return upazilaList.filter(upazila => upazila.district_id === districtId)
            }

            return upazilaList
        },
        getUnionList (upazilaId = null) {
            const unionList = this.$store.state.ExternalUserIrrigation.commonObj.unionList.filter(item => item.status === 0)
            if (upazilaId) {
                return unionList.filter(union => union.upazilla_id === upazilaId)
            }

            return unionList
        },
        getCityCorporationList (districtId) {
            const objectData = this.$store.state.ExternalUserIrrigation.commonObj.cityCorporationList.filter(item => item.status === 0 && item.district_id === districtId)
            return objectData.map((obj, key) => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.value, text: obj.text_bn }
                } else {
                    return { value: obj.value, text: obj.text_en }
                }
            })
        },
        getPauroshobaList (upazillaId = null) {
            const objectData = this.$store.state.ExternalUserIrrigation.commonObj.pauroshobaList.filter(item => item.upazilla_id === upazillaId)
            if (upazillaId) {
                return objectData.map((obj, key) => {
                    if (this.$i18n.locale === 'bn') {
                        return { value: obj.value, text: obj.text_bn }
                    } else {
                        return { value: obj.value, text: obj.text_en }
                    }
                })
            }
        },
        getWardListByCityCorportaion (cityCorpId) {
            const objectData = this.$store.state.ExternalUserIrrigation.commonObj.wardList
            const wardObjectList = objectData.filter(item => item.city_corporation_id === cityCorpId && item.status === 0)
            return wardObjectList.map((obj, key) => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.value, text: obj.text_bn }
                } else {
                    return { value: obj.value, text: obj.text }
                }
            })
        },
        getWardListByPauroshoba (pauroshobaId) {
            const objectData = this.$store.state.ExternalUserIrrigation.commonObj.wardList
            const wardObjectList = objectData.filter(item => item.pauroshoba_id === pauroshobaId && item.status === 0)
            return wardObjectList.map((obj, key) => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.value, text: obj.text_bn }
                } else {
                    return { value: obj.value, text: obj.text }
                }
            })
        },
        getWardListByUnion (unionId) {
            const objectData = this.$store.state.ExternalUserIrrigation.commonObj.wardList
            const wardObjectList = objectData.filter(item => item.union_id === unionId && item.status === 0)
            return wardObjectList.map((obj, key) => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.value, text: obj.text_bn }
                } else {
                    return { value: obj.value, text: obj.text }
                }
            })
        }
    }
}
</script>

<template>
  <b-container fluid>
    <b-overlay :show="loading">
      <b-row>
        <b-col sm="12">
          <b-card no-body>
            <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
              <b-form @submit.prevent="handleSubmit(annexure3Submit)" @reset.prevent="reset" >
                <iq-card>
                  <template v-slot:headerTitle>
                    <div class="text-center">
                      <h5 class="card-title">{{ $t('admission_form.annexure_3_info') }}</h5>
                    </div>
                  </template>
                  <template v-slot:body>
                    <b-row>
                      <b-col sm="3" md="3" xl="3">
                        <ValidationProvider name="Evaluation Year" vid="year" rules="required|min_value:1">
                          <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="year "
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('admission_form.e_year')}}
                            </template>
                            <b-form-select
                              plain
                              v-model="annexureThreeData.year"
                              :options="evaYearList"
                              id="year"
                              :state="errors[0] ? false : (valid ? true : null)"
                            >
                              <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col sm="3" md="3" xl="3">
                        <ValidationProvider name="Performance Evaluation Type" vid="type" rules="required|min_value:1">
                          <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="type"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('admission_form.e_performance')}}
                            </template>
                            <b-form-select
                              plain
                              v-model="annexureThreeData.type"
                              :options="performanceEvalList"
                              id="type"
                              :state="errors[0] ? false : (valid ? true : null)"
                            >
                              <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col sm="4" md="4" xl="4">
                        <ValidationProvider name="Attachment" vid="attachment">
                            <b-form-group
                              label-for="attachment"
                              slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('globalTrans.attachment')}} <span style="color: red"> {{ $t('globalTrans.attachment_pdf')}} </span>
                            </template>
                            <b-form-file
                            plain
                                id="attachment"
                                v-model="annexureThreeData.attachment"
                                @change="onAttachmentChange"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                              </b-form-file>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                      </b-col>
                      <b-col sm="2" md="2" xl="2">
                        <div style="margin-top:35px;">
                          <b-button type="submit" variant="success" class="mr-2 btn-sm">{{ $t('admission_form.add') }}</b-button>
                        </div>
                      </b-col>
                    </b-row>
                    <b-table-simple bordered class="table-responsive" style="font-size:14px">
                      <thead>
                        <tr class="bg-primary">
                          <th style="width:35%;">{{ $t('admission_form.e_year') }}</th>
                          <th style="width:30%;">{{ $t('admission_form.e_performance') }}</th>
                          <th style="width:30%;">{{ $t('globalTrans.attachment') }}</th>
                          <th style="width:25%;">{{ $t('globalTrans.action') }}</th>
                        </tr>
                      </thead>
                      <b-tbody>
                          <template v-if="annexure_three_info.length > 0">
                            <b-tr v-for="(item, index) in annexure_three_info" :key="index">
                              <template v-if="item.year > 0">
                                <b-td style="width:25%;">
                                  {{getYearName(item.year)}}
                                </b-td>
                                <b-td style="width:25%;">
                                  {{getEvaluationName(item.type)}}
                                </b-td>
                                <b-td style="width:25%;">
                                  <a target="_blank" v-if="item.attachment" :href="incentiveGrantServiceBaseUrl + 'storage/annexureThree/' + item.attachment"><i class="fa fa-cloud" aria-hidden="true"></i> {{ $t('globalTrans.attachment_download') }}</a>
                                </b-td>
                                <b-td style="width:25%;">
                                  <span class="d-flex text-center">
                                    <b-button type="button" @click="deleteItem(item.id)" class="btn btn- iq-bg-danger btn-sm ml-1" variant="danger"><i class="ri-delete-bin-line m-0"></i></b-button>
                                  </span>
                                </b-td>
                              </template>
                            </b-tr>
                          </template>
                          <template v-else>
                            <b-tr>
                              <b-td colspan="5" class="text-center"> {{ $t('ditfConfig.notFound') }} </b-td>
                            </b-tr>
                          </template>
                      </b-tbody>
                    </b-table-simple>
                  </template>
                </iq-card>
              </b-form>
            </ValidationObserver>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { annexure3Store, annexure3Delete } from '../../../api/routes'

export default {
  props: ['annexure_three_info'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
      incentiveGrantServiceBaseUrl: incentiveGrantServiceBaseUrl,
      loading: false,
      annexureThreeData: {
        type: 0,
        year: 0,
        attachment: ''
      },
      performanceEvaluationList: [
        {
          id: 1,
          name: 'Extra Ordinary',
          name_bn: 'অসাধারণ'
        },
        {
          id: 2,
          name: 'Very Good',
          name_bn: 'খুব ভালো'
        },
        {
          id: 3,
          name: 'Good',
          name_bn: 'ভালো'
        }
      ],
      evaluationYearList: [
        {
          id: 1,
          name: '2015',
          name_bn: '২০১৫'
        },
        {
          id: 2,
          name: '2016',
          name_bn: '২০১৬'
        },
        {
          id: 3,
          name: '2017',
          name_bn: '২০১৭'
        },
        {
          id: 4,
          name: '2018',
          name_bn: '২০১৮'
        },
        {
          id: 5,
          name: '2019',
          name_bn: '২০১৯'
        },
        {
          id: 6,
          name: '2020',
          name_bn: '২০২০'
        },
        {
          id: 7,
          name: '2021',
          name_bn: '২০২১'
        },
        {
          id: 8,
          name: '2022',
          name_bn: '২০২২'
        },
        {
          id: 9,
          name: '2023',
          name_bn: '২০২৩'
        },
        {
          id: 10,
          name: '2024',
          name_bn: '২০২৪'
        }
      ]
    }
  },
  computed: {
    performanceEvalList: function () {
      return this.performanceEvaluationList.map((obj, key) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.id, text: obj.name_bn }
        } else {
          return { value: obj.id, text: obj.name }
        }
      })
    },
    evaYearList: function () {
      return this.evaluationYearList.map((obj, key) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.id, text: obj.name_bn }
        } else {
          return { value: obj.id, text: obj.name }
        }
      })
    }
  },
  mounted () {
    core.index()
  },
  methods: {
    onAttachmentChange (e) {
      const input = event.target
      if (input.files && input.files[0]) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.annexureThreeData.attachment = e.target.result
        }
        reader.readAsDataURL(input.files[0])
      } else {
        this.annexureThreeData.attachment = ''
      }
    },
    async annexure3Submit () {
      this.loading = true
      let result = null
      const generalInfoID = this.$store.state.ExternalUserIrrigation.incentiveGrant.applicantDetails.id
      const formData = Object.assign({}, this.annexureThreeData, { app_general_info_id: generalInfoID })

      result = await RestApi.postData(incentiveGrantServiceBaseUrl, annexure3Store, formData)

      this.loading = false
      if (result.success) {
        this.annexure_three_info = result.data
        this.$toast.success({
          title: 'Success',
          message: this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
      } else {
        this.$toast.error({
          title: 'Error',
          message: this.$t('globalTrans.form_error_msg'),
          color: '#D6E09B'
        })
        this.$refs.form.setErrors(result.errors)
      }
    },
    async deleteItem (ID) {
      this.loading = true
      let result = null

      result = await RestApi.putData(incentiveGrantServiceBaseUrl, `${annexure3Delete}/${ID}`)

      this.loading = false
      if (result.success) {
        this.annexure_three_info = result.data
        this.$toast.success({
          title: 'Success',
          message: this.$t('globalTrans.deleteMsg'),
          color: '#D6E09B'
        })
        // this.$router.push('/grant-panel/application-form')
      } else {
        this.$toast.error({
          title: 'Error',
          message: this.$t('globalTrans.form_error_msg'),
          color: '#D6E09B'
        })
        this.$refs.form.setErrors(result.errors)
      }
    },
    getYearName (ID) {
      const data = this.evaluationYearList.find(item => item.id === ID)
      if (data) {
        return this.$i18n.locale === 'bn' ? data.name_bn : data.name
      }
    },
    getEvaluationName (ID) {
      const data = this.performanceEvaluationList.find(item => item.id === ID)
      if (data) {
        return this.$i18n.locale === 'bn' ? data.name_bn : data.name
      }
    }
  }
}
</script>

<template>
  <b-container fluid>
    <b-overlay :show="loading">
      <b-row>
        <b-col sm="12">
          <b-card no-body>
            <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
              <b-form  @submit.prevent="handleSubmit(save)" @reset.prevent="reset" >
                <iq-card>
                  <template v-slot:headerTitle>
                    <div class="text-center">
                      <h5 class="card-title">{{ $t('admission_form.training_details') }}</h5>
                    </div>
                  </template>
                  <template v-slot:body>
                    <b-row>
                      <b-col lg="11" sm="10">
                        <b-row>
                          <b-col lg="3" sm="8">
                            <ValidationProvider name="Training Type" vid="training_type" rules="required|min_value:1">
                              <b-form-group
                                label-for="training_type"
                                slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                {{ $t('admission_form.training_type')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                plain
                                v-model="trainingData.training_type"
                                :options="trainingTypeList"
                                id="training_type"
                                :state="errors[0] ? false : (valid ? true : null)"
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="3">
                            <ValidationProvider name="Training Title" vid="training_title" rules="required|min_value:1">
                              <b-form-group
                                label-for="training_title"
                                slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                {{ $t('admission_form.training_title')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                plain
                                v-model="trainingData.training_title"
                                :options="trainingTitleList"
                                id="education_level_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="3">
                            <ValidationProvider name="Batch">
                              <b-form-group
                                label-for="batch"
                                slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                {{ $t('admission_form.batch')}}
                              </template>
                                <b-form-input
                                id="batch"
                                v-model="trainingData.batch"
                                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="3" sm="8">
                            <ValidationProvider name="Venue (En)" rules="required">
                              <b-form-group
                                label-for="venue"
                                slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                {{ $t('admission_form.venue_en')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                  id="committee_name"
                                  v-model="trainingData.venue"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="3">
                            <ValidationProvider name="Venu (Bn)" vid="venue_bn" rules="required">
                              <b-form-group
                                label-for="venue_bn"
                                slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                {{ $t('admission_form.venue_bn')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                  id="venue_bn"
                                  v-model="trainingData.venue_bn"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="3">
                            <ValidationProvider name="From Date" vid="date_from" rules="required">
                              <b-form-group
                                label-for="date_from"
                                slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                {{ $t('globalTrans.from_date')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                class="date-picker"
                                v-model="trainingData.from_date"
                                :placeholder="$t('globalTrans.select_date')"
                                :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="3">
                            <ValidationProvider name="To Date" vid="to_date" rules="required">
                              <b-form-group
                                label-for="to_date"
                                slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                {{ $t('globalTrans.to_date')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                class="date-picker"
                                v-model="trainingData.to_date"
                                :placeholder="$t('globalTrans.select_date')"
                                :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                  {{ errors[0] }}
                              </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="3">
                            <ValidationProvider name="Attachment" vid="attachment">
                              <b-form-group
                                label-for="attachment"
                                slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                {{ $t('admission_form.training_certificate')}}
                              </template>
                              <b-form-file
                                  plain
                                  id="attachment"
                                  @change="fileChange"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                </b-form-file>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                        </b-row>
                      </b-col>
                      <b-col lg="1" sm="2">
                        <div style="margin-top:120px;">
                          <b-button type="submit" variant="primary" class="mr-2">{{ $t('admission_form.add') }}</b-button>
                        </div>
                      </b-col>
                    </b-row>
                  </template>
                </iq-card>
              </b-form>
            </ValidationObserver>
            <template v-if="trainingList.length > 0">
              <b-row>
                <b-col lg="12" sm="12" class="table-responsive">
                  <b-table :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="trainingList" :fields="columns">
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + 1) }}
                    </template>
                    <template v-slot:cell(attachment)="data">
                      <a v-if="data.item.attachment !== null" :href="baseUrl + 'download-attachment?file=uploads/training-information/original/' + data.item.attachment" title="Attachment" class="mr-3"><i class="ri-download-cloud-fill"></i></a>
                    </template>
                    <template v-slot:cell(training_title)="data">
                      {{ getTrainingTitle(data.item.training_title) }}
                    </template>
                    <template v-slot:cell(training_type)="data">
                      {{ getTrainingType(data.item.training_type) }}
                    </template>
                    <template v-slot:cell(action)="data">
                      <b-button title="Edit" v-b-modal.modal-4 variant=" iq-bg-success mr-1" size="sm" @click="edit(data.item)"><i class="ri-ball-pen-fill m-0"></i></b-button>
                      <b-button title="Delete" variant=" iq-bg-danger" size="sm" @click="remove(data.item, data.index)"><i class="ri-delete-bin-line m-0"></i></b-button>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
            </template>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { trainingStore, trainingDestroy } from '../../../api/routes'
import flatpickr from 'flatpickr'

export default {
  props: ['training_info'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
      loading: false,
      baseUrl: incentiveGrantServiceBaseUrl,
      tmpAttachment: [],
      trainingData: {
        training_title: 0,
        venue: '',
        venue_bn: '',
        from_date: '',
        to_date: '',
        training_type: 0,
        batch: '',
        attachment: null
      },
      trainingList: [],
      errors: []
    }
  },
  created () {
    if ((this.training_info.length > 0) && (this.training_info[0].venue !== '')) {
      this.trainingList = this.training_info
    }
  },
  computed: {
    trainingTypeList: function () {
      return this.$store.state.ExternalUserIrrigation.incentiveGrant.trainingTypeList.map(item => {
        if (this.$i18n.locale === 'bn') {
          return Object.assign({}, item, { text: item.text_bn })
        } else {
          return Object.assign({}, item, { text: item.text_en })
        }
      })
    },
    trainingTitleList: function () {
      return this.$store.state.ExternalUserIrrigation.incentiveGrant.trainingTitleList.map(item => {
        if (this.$i18n.locale === 'bn') {
          return Object.assign({}, item, { text: item.text_bn })
        } else {
          return Object.assign({}, item, { text: item.text_en })
        }
      })
    },
    columns () {
      const labels = [
        { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
        { label: this.$t('admission_form.training_type'), class: 'text-left' },
        { label: this.$t('admission_form.training_title'), class: 'text-left' },
        { label: this.$t('admission_form.batch'), class: 'text-left' },
        { label: this.$t('globalTrans.attachment'), class: 'text-left' },
        { label: this.$t('globalTrans.action'), class: 'text-center' }
      ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'training_type' },
          { key: 'training_title' },
          { key: 'batch' },
          { key: 'attachment' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'training_type' },
          { key: 'training_title' },
          { key: 'batch' },
          { key: 'attachment' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    }
  },
  mounted () {
    flatpickr('.date-picker', {})
    core.index()
  },
  methods: {
    async save () {
      this.loading = true
      let result = null
      const generalInfoID = this.$store.state.ExternalUserIrrigation.incentiveGrant.applicantDetails.id
      const finalTrainingData = Object.assign({}, this.trainingData, { app_general_info_id: generalInfoID })

      var formData = new FormData()
      Object.keys(finalTrainingData).map((key) => {
        if (key === 'attachment') {
          formData.append(key, this.tmpAttachment)
        } else {
          formData.append(key, finalTrainingData[key])
        }
      })

      result = await RestApi.postData(incentiveGrantServiceBaseUrl, trainingStore, formData)

      this.loading = false
      if (result.success) {
        this.$toast.success({
          title: this.$t('globalTrans.success'),
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        this.$nextTick(() => {
          this.$refs.form.reset()
        })
        if (this.training_info.length > 0 && this.trainingData.training_title !== 0) {
          const trainingExist = this.trainingList.find(train => train.id === result.data.id)
          if (typeof trainingExist !== 'undefined') {
            this.trainingList = this.trainingList.filter(train => train.id !== trainingExist.id)
            this.trainingList.push(result.data)
          } else {
            this.trainingList.push(result.data)
          }
        } else {
          this.trainingList.push(result.data)
        }

        this.trainingData = Object.assign({},
          {
            training_title: 0,
            venue: '',
            venue_bn: '',
            from_date: '',
            to_date: '',
            training_type: 0,
            batch: '',
            attachment: ''
          }
        )
      } else {
        this.$refs.form.setErrors(result.errors)
        this.$toast.error({
          title: 'Error',
          message: 'Operation failed! Please, try again.'
        })
      }
    },
    getTrainingTitle (titleId) {
      const trainingTitle = this.$store.state.ExternalUserIrrigation.incentiveGrant.trainingTitleList.find(tmp => tmp.value === parseInt(titleId))
      return trainingTitle !== undefined ? (this.$i18n.locale === 'bn' ? trainingTitle.text_bn : trainingTitle.text_en) : ''
    },
    getTrainingType (typeId) {
      const trainingType = this.$store.state.ExternalUserIrrigation.incentiveGrant.trainingTypeList.find(tmp => tmp.value === parseInt(typeId))
      return trainingType !== undefined ? (this.$i18n.locale === 'bn' ? trainingType.text_bn : trainingType.text_en) : ''
    },
    trainingSubmit () {
      if (this.trainingList.length > 0) {
        return { success: 1 }
      } else {
        this.$toast.error({
          title: 'Error',
          message: 'Please Fill the form'
        })
      }
    },
    fileChange (e) {
      this.tmpAttachment = e.target.files[0]
    },
    edit (item) {
      this.trainingData = item
    },
    remove (item, key) {
      this.$swal({
        title: this.$t('globalTrans.deleteConfirmMsg'),
        showCancelButton: true,
        confirmButtonText: this.$t('globalTrans.yes'),
        cancelButtonText: this.$t('globalTrans.no'),
        focusConfirm: false
      }).then((result) => {
        if (result.isConfirmed) {
          this.destroy(item.id, key)
        }
      })
    },
    destroy (itemId, key) {
      RestApi.deleteData(incentiveGrantServiceBaseUrl, `${trainingDestroy}/${itemId}`).then(response => {
        if (response.success) {
          this.$toast.success({
            title: 'Success',
            message: 'Data Deleted Successfully',
            color: '#D6E09B'
          })
          this.trainingList.splice(key, 1)
        } else {
          this.$toast.error({
            title: 'Error',
            message: 'Operation failed! Please, try again.'
          })
        }
      })
    }
  }
}
</script>

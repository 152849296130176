<template>
  <b-container fluid>
    <b-overlay :show="loading">
      <b-row>
        <b-col sm="12">
          <b-card no-body>
            <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
              <b-form  @submit.prevent="handleSubmit(save)" @reset.prevent="reset" >
                <iq-card>
                  <template v-slot:headerTitle>
                    <div class="text-center">
                      <h5 class="card-title">{{ $t('admission_form.publication_information') }}</h5>
                    </div>
                  </template>
                  <template v-slot:body>
                    <b-row>
                      <b-col sm="4">
                        <ValidationProvider name="Author Type" vid="author_type" rules="required|min_value:1">
                          <b-form-group
                            label-for="author_type"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                                {{ $t('admission_form.author_type') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                              plain
                              v-model="publicationData.author_type"
                              :options="authorTypeList"
                              id="author_type"
                              :state="errors[0] ? false : (valid ? true : null)"
                            >
                              <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col sm="4">
                        <ValidationProvider name="Production Type" vid="publication_type" rules="required|min_value:1">
                          <b-form-group
                            label-for="publication_type"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                                {{ $t('admission_form.publication_type') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                              plain
                              v-model="publicationData.publication_type"
                              :options="publicationTypeList"
                              id="publication_type"
                              :state="errors[0] ? false : (valid ? true : null)"
                            >
                              <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col sm="4">
                        <ValidationProvider name="Postion Name (En)" vid="publication_name" rules="required">
                          <b-form-group
                            label-for="publication_name"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('admission_form.publication_name_en') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              plain
                              v-model="publicationData.publication_name"
                              id="publication_name"
                              :state="errors[0] ? false : (valid ? true : null)"
                            >
                            </b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col sm="4">
                        <ValidationProvider name="Postion Name (Bn)" vid="publication_name_bn" rules="required">
                          <b-form-group
                            label-for="publication_name_bn"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('admission_form.publication_name_bn') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              plain
                              v-model="publicationData.publication_name_bn"
                              id="publication_name_bn"
                              :state="errors[0] ? false : (valid ? true : null)"
                            >
                            </b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col sm="3">
                        <ValidationProvider name="Attachment">
                          <b-form-group
                            label-for="attachment"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('globalTrans.attachment') }}
                            </template>
                            <b-form-file
                              plain
                              @change="onAttachmentChange"
                              id="attachment"
                              :state="errors[0] ? false : (valid ? true : null)"
                            >
                            </b-form-file>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col sm="2">
                        <div style="margin-top:35px;">
                          <b-button type="submit" variant="primary" class="mr-2">{{ $t('admission_form.add') }}</b-button>
                        </div>
                      </b-col>
                    </b-row>
                  </template>
                </iq-card>
              </b-form>
            </ValidationObserver>
            <template v-if="publicationList.length > 0">
              <b-row>
                <b-col lg="12" sm="12" class="table-responsive">
                  <b-table :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="publicationList" :fields="columns">
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + 1) }}
                    </template>
                    <template v-slot:cell(attachment)="data">
                      <a v-if="data.item.attachment !== null" :href="baseUrl + 'download-attachment?file=uploads/publication-information/original/' + data.item.attachment" title="Attachment" class="mr-3"><i class="ri-download-cloud-fill"></i></a>
                    </template>
                    <template v-slot:cell(author_type)="data">
                      {{ getPubAuthorType(data.item.author_type) }}
                    </template>
                    <template v-slot:cell(publication_type)="data">
                      {{ getPublicationType(data.item.publication_type) }}
                    </template>
                    <template v-slot:cell(action)="data">
                      <b-button title="Edit" v-b-modal.modal-4 variant=" iq-bg-success mr-1" size="sm" @click="edit(data.item)"><i class="ri-ball-pen-fill m-0"></i></b-button>
                      <b-button title="Delete" variant=" iq-bg-danger" size="sm" @click="remove(data.item, data.index)"><i class="ri-delete-bin-line m-0"></i></b-button>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
            </template>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { PublicationInfoStore, PublicationInfoDestroy } from '../../../api/routes'

export default {
  props: ['publication_info'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
      loading: false,
      baseUrl: incentiveGrantServiceBaseUrl,
      tmpAttachment: [],
      publicationData: {
        publication_name: '',
        publication_name_bn: '',
        author_type: 0,
        publication_type: 0,
        attachment: null
      },
      publicationList: [],
      orgList: []
    }
  },
  created () {
    this.orgList = this.getOrganization()
    if ((this.publication_info.length > 0) && (this.publication_info[0].publication_name !== '')) {
      this.publicationList = this.publication_info
    }
  },
  computed: {
    authorTypeList: function () {
      return this.$store.state.ExternalUserIrrigation.incentiveGrant.authorTypeList.map(item => {
        if (this.$i18n.locale === 'bn') {
          return Object.assign({}, item, { text: item.text_bn })
        } else {
          return Object.assign({}, item, { text: item.text_en })
        }
      })
    },
    publicationTypeList: function () {
      return this.$store.state.ExternalUserIrrigation.incentiveGrant.publicationTypeList.map(item => {
        if (this.$i18n.locale === 'bn') {
          return Object.assign({}, item, { text: item.text_bn })
        } else {
          return Object.assign({}, item, { text: item.text_en })
        }
      })
    },
    orgType: function () {
      return this.$store.state.ExternalUserIrrigation.incentiveGrant.applicantDetails.can_org_type
    },
    columns () {
      const labels = [
        { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
        { label: this.$t('admission_form.author_type'), class: 'text-left' },
        { label: this.$t('admission_form.publication_type'), class: 'text-left' },
        { label: this.$t('admission_form.publication_name'), class: 'text-left' },
        { label: this.$t('globalTrans.attachment'), class: 'text-left' },
        { label: this.$t('globalTrans.action'), class: 'text-center' }
      ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'author_type' },
          { key: 'publication_type' },
          { key: 'publication_name_bn' },
          { key: 'attachment' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'author_type' },
          { key: 'publication_type' },
          { key: 'publication_name' },
          { key: 'attachment' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    }
  },
  mounted () {
    core.index()
  },
  methods: {
    async save () {
      this.loading = true
      let result = null
      const generalInfoID = this.$store.state.ExternalUserIrrigation.incentiveGrant.applicantDetails.id
      const finalPublicationData = Object.assign({}, this.publicationData, { app_general_info_id: generalInfoID })

      var formData = new FormData()
      Object.keys(finalPublicationData).map((key) => {
        if (key === 'attachment') {
          formData.append(key, this.tmpAttachment)
        } else {
          formData.append(key, finalPublicationData[key])
        }
      })

      result = await RestApi.postData(incentiveGrantServiceBaseUrl, PublicationInfoStore, formData)

      this.loading = false
      if (result.success) {
        this.$toast.success({
          title: 'Success',
          message: this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        this.$nextTick(() => {
          this.$refs.form.reset()
        })
        if (this.publication_info.length > 0 && this.publicationData.publication_name !== '') {
          const publicationExist = this.publicationList.find(publication => publication.id === result.data.id)
          if (typeof publicationExist !== 'undefined') {
            this.publicationList = this.publicationList.filter(publication => publication.id !== publicationExist.id)
            this.publicationList.push(result.data)
          } else {
            this.publicationList.push(result.data)
          }
        } else {
          this.publicationList.push(result.data)
        }

        this.publicationData = Object.assign({},
          {
            publication_name: '',
            publication_name_bn: '',
            author_type: 0,
            publication_type: 0,
            attachment: null
          }
        )
      } else {
        this.$toast.error({
          title: 'Error',
          message: this.$t('globalTrans.form_error_msg'),
          color: '#D6E09B'
        })
        this.$refs.form.setErrors(result.errors)
      }
    },
    getOrganization () {
      if (this.orgType === 2) {
        const narseInstituteList = this.$store.state.ExternalUserIrrigation.incentiveGrant.narseInstituteList.filter(item => item.status === 1)
        return narseInstituteList.map(item => {
          return Object.assign({}, item, { value: item.value, text: item.text_en })
        })
      } else {
        const orgList = this.$store.state.orgList
        return orgList.map(item => {
          return Object.assign({}, item, { value: item.value, text: item.text_en })
        })
      }
    },
    getPubAuthorType (authorTypeId) {
      const author = this.$store.state.ExternalUserIrrigation.incentiveGrant.authorTypeList.find(tmp => tmp.value === parseInt(authorTypeId))
      return author !== undefined ? (this.$i18n.locale === 'bn' ? author.text_bn : author.text_en) : ''
    },
    getPublicationType (pubTypeId) {
      const publicationType = this.$store.state.ExternalUserIrrigation.incentiveGrant.publicationTypeList.find(tmp => tmp.value === parseInt(pubTypeId))
      return publicationType !== undefined ? (this.$i18n.locale === 'bn' ? publicationType.text_bn : publicationType.text_en) : ''
    },
    annexure2PublicationSubmit () {
      if (this.publicationList.length > 0) {
        return { success: 1 }
      } else {
        this.$toast.error({
          title: 'Error',
          message: 'Please Fill the form'
        })
      }
    },
    onAttachmentChange (e) {
      this.tmpAttachment = e.target.files[0]
    },
    edit (item) {
      this.publicationData = item
    },
    remove (item, key) {
      this.$swal({
        title: this.$t('globalTrans.deleteConfirmMsg'),
        showCancelButton: true,
        confirmButtonText: this.$t('globalTrans.yes'),
        cancelButtonText: this.$t('globalTrans.no'),
        focusConfirm: false
      }).then((result) => {
        if (result.isConfirmed) {
          this.destroy(item.id, key)
        }
      })
    },
    destroy (itemId, key) {
      RestApi.deleteData(incentiveGrantServiceBaseUrl, `${PublicationInfoDestroy}/${itemId}`).then(response => {
        if (response.success) {
          this.$toast.success({
            title: 'Success',
            message: 'Data Deleted Successfully',
            color: '#D6E09B'
          })
          this.publicationList.splice(key, 1)
        } else {
          this.$toast.error({
            title: 'Error',
            message: 'Operation failed! Please, try again.'
          })
        }
      })
    }
  }
}
</script>

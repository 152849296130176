<template>
  <b-container fluid>
    <b-overlay :show="loading">
      <b-row>
        <b-col sm="12">
          <b-card no-body>
            <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
              <b-form @submit.prevent="handleSubmit(save)" @reset.prevent="reset" >
                <iq-card>
                  <template v-slot:headerTitle>
                    <div class="text-center">
                      <h5 class="card-title">{{ $t('admission_form.employment_records') }}</h5>
                    </div>
                  </template>
                  <template v-slot:body>
                    <b-row>
                      <b-col sm="3">
                        <ValidationProvider name="Organization" vid="org_id" rules="required|min_value:-1">
                          <b-form-group
                            label-for="org_id"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{$t('admission_form.organization')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                              plain
                              v-model="employmentRecord.org_id"
                              :options="customOrgList"
                              id="org_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                            >
                              <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <template v-if="otherOrgShow === true ">
                        <b-col sm="3">
                          <ValidationProvider name="Other Organization" vid="other_organization" rules="required">
                            <b-form-group
                              label-for="org_id"
                              slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                {{$t('irriPumpInformation.other_organization')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                plain
                                v-model="employmentRecord.other_organization"
                                id="position"
                                :state="errors[0] ? false : (valid ? true : null)"
                              >
                              </b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </template>
                      <!-- <template v-if="otherOrgShow === false ">
                        <b-col lg="3" sm="4">
                        </b-col>
                      </template> -->
                      <b-col sm="2">
                        <div class="mt-4">
                          <b-button style="margin-top:10px;" type="submit" variant="primary" class="mr-2">{{ $t('admission_form.add') }}</b-button>
                        </div>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="3">
                        <ValidationProvider name="Postion Name (En)" vid="position" rules="required">
                          <b-form-group
                            label-for="position"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('admission_form.position_name_en') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              plain
                              v-model="employmentRecord.position"
                              id="position"
                              :state="errors[0] ? false : (valid ? true : null)"
                            >
                            </b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col sm="3">
                        <ValidationProvider name="Postion Name (Bn)" vid="position_bn" rules="required">
                          <b-form-group
                            label-for="position_bn"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                                {{ $t('admission_form.position_name_bn') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              plain
                              v-model="employmentRecord.position_bn"
                              id="position_bn"
                              :state="errors[0] ? false : (valid ? true : null)"
                            >
                            </b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col sm="3">
                        <ValidationProvider name="Place of Posting (En)" vid="posting" rules="required">
                          <b-form-group
                            label-for="posting"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('admission_form.place_of_posting_en') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              plain
                              v-model="employmentRecord.posting"
                              id="posting"
                              :state="errors[0] ? false : (valid ? true : null)"
                            >
                            </b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col sm="3">
                        <ValidationProvider name="Place of Posting (Bn)" vid="posting_bn" rules="required">
                          <b-form-group
                            label-for="posting_bn"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('admission_form.place_of_posting_bn') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              plain
                              v-model="employmentRecord.posting_bn"
                              id="posting_bn"
                              :state="errors[0] ? false : (valid ? true : null)"
                            >
                            </b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col sm="3">
                        <ValidationProvider name="Employement Start Date" vid="start_date" rules="required">
                          <b-form-group
                            label-for="start_date"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('admission_form.employement_start_date') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              class="date-picker"
                              v-model="employmentRecord.start_date"
                              id="start_date"
                              :state="errors[0] ? false : (valid ? true : null)"
                            >
                            </b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col sm="3">
                        <ValidationProvider name="Employement End Date" vid="end_date" rules="required">
                          <b-form-group
                            label-for="end_date"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('admission_form.employement_end_date') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              class="date-picker"
                              v-model="employmentRecord.end_date"
                              id="end_date"
                              :state="errors[0] ? false : (valid ? true : null)"
                            >
                            </b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col sm="3">
                        <ValidationProvider name="Remark (En)" vid="remark" rules="required">
                          <b-form-group
                            label-for="remark"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('admission_form.remark_en') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              plain
                              v-model="employmentRecord.remark"
                              id="remark"
                              :state="errors[0] ? false : (valid ? true : null)"
                            >
                            </b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col sm="3">
                        <ValidationProvider name="Remark (Bn)" vid="remark_bn" rules="required">
                          <b-form-group
                            label-for="remark_bn"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('admission_form.remark_bn') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              plain
                              v-model="employmentRecord.remark_bn"
                              id="remark_bn"
                              :state="errors[0] ? false : (valid ? true : null)"
                            >
                            </b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                    </b-row>
                  </template>
                </iq-card>
              </b-form>
            </ValidationObserver>
            <template v-if="employmentRecordList.length > 0">
              <b-row>
                <b-col lg="12" sm="12" class="table-responsive">
                  <b-table :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="employmentRecordList" :fields="columns">
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + 1) }}
                    </template>
                    <!-- <template v-slot:cell(org_id)="data">
                      {{ getOrganizationName(data.item) }}
                    </template> -->
                    <template v-slot:cell(start_date)="data">
                      {{ data.item.start_date | dateFormat }}
                    </template>
                    <template v-slot:cell(end_date)="data">
                      {{ data.item.end_date | dateFormat }}
                    </template>
                    <template v-slot:cell(action)="data">
                      <b-button title="Edit" v-b-modal.modal-4 variant=" iq-bg-success mr-1" size="sm" @click="edit(data.item)"><i class="ri-ball-pen-fill m-0"></i></b-button>
                      <b-button title="Delete" variant=" iq-bg-danger" size="sm" @click="remove(data.item, data.index)"><i class="ri-delete-bin-line m-0"></i></b-button>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
            </template>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { EmployeeRecordStore, EmployeeRecordDestroy } from '../../../api/routes'
import flatpickr from 'flatpickr'

export default {
  props: ['employee_record_info', 'customOrgList'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
      loading: false,
      otherOrgShow: false,
      employmentRecord: {
        can_org_type: 0,
        org_id: 0,
        position: '',
        position_bn: '',
        posting: '',
        posting_bn: '',
        remark: '',
        remark_bn: '',
        start_date: '',
        end_date: '',
        other_organization: ''
      },
      employmentRecordList: []
    }
  },
  created () {
    this.orgList = this.getOrganization()
    this.orgList.push(
      { value: -1, text: this.$i18n.locale === 'bn' ? 'অন্য' : 'Other' }
    )
    if ((this.employee_record_info.length > 0) && (this.employee_record_info[0].position !== '')) {
      this.employmentRecordList = this.getRelationalData(this.employee_record_info)
    }
  },
  computed: {
    columns () {
      const labels = [
        { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
        { label: this.$t('globalTrans.organization'), class: 'text-left' },
        { label: this.$t('admission_form.position_name'), class: 'text-left' },
        { label: this.$t('admission_form.place_of_posting'), class: 'text-left' },
        { label: this.$t('admission_form.employement_start_date'), class: 'text-left' },
        { label: this.$t('admission_form.employement_end_date'), class: 'text-left' },
        { label: this.$t('globalTrans.action'), class: 'text-center' }
      ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'org_name' },
          { key: 'position_bn' },
          { key: 'posting_bn' },
          { key: 'start_date' },
          { key: 'end_date' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'org_name' },
          { key: 'position' },
          { key: 'posting' },
          { key: 'start_date' },
          { key: 'end_date' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    }
  },
  mounted () {
    core.index()
    flatpickr('.date-picker', {})
  },
  watch: {
    'employmentRecord.org_id': function (newVal) {
      if (newVal === -1) {
        this.otherOrgShow = true
      } else {
        this.otherOrgShow = false
      }
    }
  },
  methods: {
    async save () {
      this.loading = true
      let result = null
      const generalInfoID = this.$store.state.ExternalUserIrrigation.incentiveGrant.applicantDetails.id
      const formData = Object.assign({}, this.employmentRecord, { app_general_info_id: generalInfoID })
      result = await RestApi.postData(incentiveGrantServiceBaseUrl, EmployeeRecordStore, formData)

      this.loading = false
      if (result.success) {
        this.$toast.success({
          title: 'Success',
          message: this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$nextTick(() => {
          this.$refs.form.reset()
        })

        const tmpData = Object.assign({}, result.data, {
          org_name: this.getOrganizationName(result.data)
        })

        if (this.employee_record_info.length > 0 && this.employmentRecord.can_org_type !== 0) {
          const employmentRecordExist = this.employmentRecordList.find(employmentRecord => employmentRecord.id === result.data.id)
          if (typeof employmentRecordExist !== 'undefined') {
            this.employmentRecordList = this.employmentRecordList.filter(employmentRecord => employmentRecord.id !== employmentRecordExist.id)
            this.employmentRecordList.push(tmpData)
          } else {
            this.employmentRecordList.push(tmpData)
          }
        } else {
          this.employmentRecordList.push(tmpData)
        }

        this.employmentRecord = Object.assign({},
          {
            can_org_type: 0,
            org_id: 0,
            position: '',
            position_bn: '',
            posting: '',
            posting_bn: '',
            remark: '',
            remark_bn: '',
            start_date: '',
            end_date: '',
            other_organization: ''
          }
        )
      } else {
        this.$toast.error({
          title: 'Error',
          message: this.$t('globalTrans.form_error_msg'),
          color: '#D6E09B'
        })
        this.$refs.form.setErrors(result.errors)
      }
    },
    annexure2EmploymentRecordSubmit () {
      if (this.employmentRecordList.length > 0) {
        return { success: 1 }
      } else {
        this.$toast.error({
          title: 'Error',
          message: 'Please Fill the form'
        })
      }
    },
    getOrganization () {
      if (parseInt(this.$store.state.ExternalUserIrrigation.incentiveGrant.applicantDetails.can_org_type) === 2) {
        const narseInstituteList = this.$store.state.ExternalUserIrrigation.incentiveGrant.narseInstituteList.filter(item => item.status === 1)
        return narseInstituteList.map(item => {
          return Object.assign({}, item, { value: item.value, text: this.$i18n.locale === 'bn' ? item.text_bn : item.text_en })
        })
      } else {
        const orgList = this.$store.state.ExternalUserIrrigation.incentiveGrant.orgList
        return orgList.map(item => {
          return Object.assign({}, item, { value: item.value, text: this.$i18n.locale === 'bn' ? item.text_bn : item.text_en })
        })
      }
    },
    getRelationalData (data) {
      return data.map(obj => {
        var orgId = ''
        if (obj.org_id > 0) {
          const org = this.$store.state.ExternalUserIrrigation.incentiveGrant.orgList.find(item => item.value === obj.org_id)
          orgId = org !== undefined ? (this.$i18n.locale === 'bn' ? org.text_bn : org.text_en) : ''
        } else {
          orgId = obj.other_organization
        }
        return Object.assign({}, obj, { org_name: orgId })
      })
    },
    getOrganizationName (data) {
      if (data.org_id > 0) {
        const org = this.$store.state.ExternalUserIrrigation.incentiveGrant.orgList.find(item => item.value === data.org_id)
        return org !== undefined ? (this.$i18n.locale === 'bn' ? org.text_bn : org.text_en) : ''
      } else {
        return data.other_organization
      }
    },
    edit (item) {
      this.employmentRecord = item
    },
    remove (item, key) {
      this.$swal({
        title: this.$t('globalTrans.deleteConfirmMsg'),
        showCancelButton: true,
        confirmButtonText: this.$t('globalTrans.yes'),
        cancelButtonText: this.$t('globalTrans.no'),
        focusConfirm: false
      }).then((result) => {
        if (result.isConfirmed) {
          this.destroy(item.id, key)
        }
      })
    },
    destroy (itemId, key) {
      RestApi.deleteData(incentiveGrantServiceBaseUrl, `${EmployeeRecordDestroy}/${itemId}`).then(response => {
        if (response.success) {
          this.$toast.success({
            title: 'Success',
            message: 'Data Deleted Successfully',
            color: '#D6E09B'
          })
          this.employmentRecordList.splice(key, 1)
        } else {
          this.$toast.error({
            title: 'Error',
            message: 'Operation failed! Please, try again.'
          })
        }
      })
    }
  }
}
</script>

<template>
  <b-container fluid>
    <b-overlay :show="loading">
      <b-row>
        <b-col sm="12">
          <b-card no-body>
            <ValidationObserver ref="formAcademic" v-slot="{ handleSubmit, reset }">
              <b-form @submit.prevent="handleSubmit(save)" @reset.prevent="reset" >
                <iq-card>
                  <template v-slot:headerTitle>
                    <div class="text-center">
                      <h5 class="card-title">{{ $t('educational_management.academic_inf') }}</h5>
                    </div>
                  </template>
                  <template v-slot:body>
                    <b-row>
                      <b-col lg="3" sm="8">
                        <ValidationProvider name="Education level" vid="education_level_id" rules="required|min_value:1">
                          <b-form-group
                            label-for="education_level_id"
                            slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                            {{ $t('admission_form.education_level')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                            plain
                            v-model="academicData.education_level_id"
                            :options="educationLevelList"
                            id="education_level_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="3">
                        <ValidationProvider name="Degree Title (En)" vid="degree_title" rules="required">
                          <b-form-group
                            label-for="district_id"
                            slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                            {{ $t('admission_form.degree_en')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                            id="committee_name"
                            v-model="academicData.degree_title"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="3">
                        <ValidationProvider name="Degree Title (Bn)" vid="degree_title_bn" rules="required">
                          <b-form-group
                            label-for="degree"
                            slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                            {{ $t('admission_form.degree_bn')}} <span class="text-danger">*</span>
                          </template>
                            <b-form-input
                            id="committee_name"
                            v-model="academicData.degree_title_bn"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="3" sm="8">
                        <ValidationProvider name="Group (En)" rules="required">
                          <b-form-group
                            label-for="group_en"
                            slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                            {{ $t('admission_form.group_en')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                              id="group"
                              v-model="academicData.group"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="3">
                        <ValidationProvider name="Group (Bn)" vid="group_bn" rules="required">
                          <b-form-group
                            label-for="group_bn"
                            slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                            {{ $t('admission_form.group_bn')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                              id="group_bn"
                              v-model="academicData.group_bn"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="3">
                        <ValidationProvider name="Institute (En)" rules="required">
                          <b-form-group
                            label-for="institute"
                            slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                            {{ $t('admission_form.name_inst_en')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                              id="institute"
                              v-model="academicData.institute"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="3" sm="8">
                        <ValidationProvider name="Institute (Bn)" vid="institute_bn" rules="required">
                          <b-form-group
                            label-for="institute_bn"
                            slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                            {{ $t('admission_form.name_inst_bn')}} <span class="text-danger">*</span>
                          </template>
                        <b-form-input
                              id="committee_name"
                              v-model="academicData.institute_bn"
                              :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="3">
                        <ValidationProvider name="Address (En)" rules="required">
                          <b-form-group
                            label-for="address"
                            slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                            {{ $t('admission_form.inst_address_en')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                              id="address"
                              v-model="academicData.address"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="3">
                        <ValidationProvider name="Address (Bn)" vid="address_bn" rules="required">
                          <b-form-group
                            label-for="address_bn"
                            slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                            {{ $t('admission_form.inst_address_bn')}} <span class="text-danger">*</span>
                          </template>
                        <b-form-input
                              id="address_bn"
                              v-model="academicData.address_bn"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="3" sm="8">
                        <ValidationProvider name="Year of Attended From" vid="year_from" rules="required">
                          <b-form-group
                            label-for="year_from"
                            slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                            {{ $t('admission_form.year_attend_from')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                            id="year_from"
                            v-model="academicData.year_from"
                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="3">
                        <ValidationProvider name="Year of Attended To" vid="year_to" rules="required">
                          <b-form-group
                            label-for="year_to"
                            slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                            {{ $t('admission_form.year_attend_to')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                            id="year_to"
                            v-model="academicData.year_to"
                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="3">
                        <ValidationProvider name="Score System" vid="score_system_id" rules="required|min_value:1">
                          <b-form-group
                            label-for="score_system_id"
                            slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                            {{ $t('admission_form.score_sys')}} <span class="text-danger">*</span>
                          </template>
                            <b-form-select
                              plain
                              v-model="academicData.score_system_id"
                              :options="scoreSysList"
                              id="score_sys"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="3" sm="8">
                          <ValidationProvider name="Class/Grade" vid="class_or_grade_id" rules="required|min_value:1">
                            <b-form-group
                              label-for="class"
                              slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('admission_form.class')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                              id="committee_name"
                              v-model="academicData.class_or_grade_id"
                              :options="classGradeList"
                              :state="errors[0] ? false : (valid ? true : null)"
                            >
                              <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                          </ValidationProvider>
                      </b-col>
                      <b-col lg="3">
                          <ValidationProvider name="Passing Year" vid="passing_year" rules="required">
                            <b-form-group
                              label-for="passing_year"
                              slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('admission_form.pass_year')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              id="passing_year"
                              v-model="academicData.passing_year"
                              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                      </b-col>
                      <b-col lg="3">
                          <ValidationProvider name="Attachment" vid="attachment">
                            <b-form-group
                              label-for="attachment"
                              slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('admission_form.acedemic_cert')}}
                            </template>
                            <b-form-file
                                id="attachment"
                                @change="onAttachmentChange"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                              </b-form-file>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                      </b-col>
                      <b-col lg="3" sm="6">
                        <div style="margin-top:34px;">
                          <b-button type="submit" variant="primary" class="mr-2">{{ $t('admission_form.add') }}</b-button>
                        </div>
                      </b-col>
                    </b-row>
                  </template>
                </iq-card>
              </b-form>
            </ValidationObserver>
            <template v-if="academicList.length > 0">
              <b-row>
                <b-col sm="12" class="table-responsive">
                  <b-table small :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="academicList" :fields="columns">
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + 1) }}
                    </template>
                    <template v-slot:cell(attachment)="data">
                      <a v-if="data.item.attachment !== null" :href="baseUrl + 'download-attachment?file=uploads/academic-information/original/' + data.item.attachment" title="Attachment" class="mr-3"><i class="ri-download-cloud-fill"></i></a>
                    </template>
                    <template v-slot:cell(action)="data">
                      <b-button title="Edit" v-b-modal.modal-4 variant=" iq-bg-success mr-1" size="sm" @click="edit(data.item)"><i class="ri-ball-pen-fill m-0"></i></b-button>
                      <b-button title="Delete" variant=" iq-bg-danger" size="sm" @click="remove(data.item, data.index)"><i class="ri-delete-bin-line m-0"></i></b-button>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
            </template>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { academicStore, academicDestroy } from '../../../api/routes'
import flatpickr from 'flatpickr'

export default {
  props: ['academic_info'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
      loading: false,
      baseUrl: incentiveGrantServiceBaseUrl,
      academicData: {
        education_level_id: 0,
        score_system_id: 0,
        class_or_grade_id: 0,
        degree_title: '',
        degree_title_bn: '',
        group: '',
        group_bn: '',
        institute: '',
        institute_bn: '',
        address: '',
        address_bn: '',
        year_from: '',
        year_to: '',
        passing_year: '',
        attachment: null
      },
      academicList: [],
      tmpAttachment: [],
      scoreSysList: [
        { value: 1, text: this.$i18n.locale === 'bn' ? 'পুরাতন' : 'Old' },
        { value: 2, text: this.$i18n.locale === 'bn' ? 'নতুন' : 'New' }
      ],
      classGradeList: [],
      divisionList: [
        {
          value: 1,
          text: this.$i18n.locale === 'bn' ? 'প্রথম বিভাগ' : 'First Division'
        },
        {
          value: 2,
          text: this.$i18n.locale === 'bn' ? 'দ্বিতীয় বিভাগ' : 'Second Division'
        },
        {
          value: 3,
          text: this.$i18n.locale === 'bn' ? 'তৃতীয় বিভাগ' : 'Third Division'
        }
      ],
      classList: [
        {
          value: 1,
          text: this.$i18n.locale === 'bn' ? 'প্রথম শ্রেণী' : 'First Class'
        },
        {
          value: 2,
          text: this.$i18n.locale === 'bn' ? 'দ্বিতীয় শ্রেণী' : 'Second Class'
        },
        {
          value: 3,
          text: this.$i18n.locale === 'bn' ? 'তৃতীয় শ্রেণী' : 'Third Class'
        }
      ],
      gradeList: [
        {
          value: 1,
          text: this.$i18n.locale === 'bn' ? 'এ+' : 'A+'
        },
        {
          value: 2,
          text: this.$i18n.locale === 'bn' ? 'এ' : 'A'
        },
        {
          value: 3,
          text: this.$i18n.locale === 'bn' ? 'এ-' : 'A-'
        },
        {
          value: 4,
          text: this.$i18n.locale === 'bn' ? 'বি' : 'B'
        },
        {
          value: 5,
          text: this.$i18n.locale === 'bn' ? 'সি' : 'C'
        },
        {
          value: 6,
          text: this.$i18n.locale === 'bn' ? 'ডি' : 'D'
        },
        {
          value: 7,
          text: this.$i18n.locale === 'bn' ? 'এফ' : 'F'
        }
      ]
    }
  },
  created () {
    if ((this.academic_info.length > 0) && (this.academic_info[0].degree_title !== '')) {
      this.academicList = this.academic_info
    }
  },
  mounted () {
    flatpickr('.date-picker', {})
    core.index()
  },
  watch: {
    'academicData.education_level_id': function (newVal, oldVal) {
      if (oldVal !== newVal) {
        this.classGradeList = this.getClassGradeList(this.academicData.education_level_id, this.academicData.score_system_id)
      }
    },
    'academicData.score_system_id': function (newVal, oldVal) {
      if (oldVal !== newVal) {
        this.classGradeList = this.getClassGradeList(this.academicData.education_level_id, this.academicData.score_system_id)
      }
    }
  },
  computed: {
    educationLevelList: function () {
      const dataEdu = this.$store.state.ExternalUserIrrigation.incentiveGrant.educationLevelList
      return dataEdu.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
        } else {
          return { value: item.value, text: item.text_en }
        }
      })
    },
    circularInfoList: function () {
      const circularList = this.$store.state.incentiveGrant.commonObj.circularInfoList.filter(item => item.status === 1)
      return circularList.map(item => {
        return Object.assign({}, item, { value: item.value, text: item.memo_no })
      })
    },
    columns () {
      const labels = [
        { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
        { label: this.$t('admission_form.degree'), class: 'text-left' },
        { label: this.$t('externalUserIrrigation.subject'), class: 'text-left' },
        { label: this.$t('admission_form.pass_year'), class: 'text-left' },
        { label: this.$t('globalTrans.attachment'), class: 'text-left' },
        { label: this.$t('globalTrans.action'), class: 'text-center' }
      ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'degree_title_bn' },
          { key: 'group_bn' },
          { key: 'passing_year' },
          { key: 'attachment' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'degree_title' },
          { key: 'group' },
          { key: 'passing_year' },
          { key: 'attachment' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    }
  },
  methods: {
    async save () {
      this.loading = true
      let result = null
      const generalInfoID = this.$store.state.ExternalUserIrrigation.incentiveGrant.applicantDetails.id
      const finalAcademicData = Object.assign({}, this.academicData, { app_general_info_id: generalInfoID })

      var formData = new FormData()
      Object.keys(finalAcademicData).map((key) => {
        if (key === 'attachment') {
          formData.append(key, this.tmpAttachment)
        } else {
          formData.append(key, finalAcademicData[key])
        }
      })

      result = await RestApi.postData(incentiveGrantServiceBaseUrl, academicStore, formData)
      this.loading = false
      if (result.success) {
        this.$toast.success({
          title: this.$t('globalTrans.success'),
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        this.$nextTick(() => {
          this.$refs.formAcademic.reset()
        })

        if (this.academicList.length > 0 && this.academicData.degree_title !== '') {
          const academicExist = this.academicList.find(academ => academ.id === result.data.id)
          if (typeof academicExist !== 'undefined') {
            this.academicList = this.academicList.filter(academ => academ.id !== academicExist.id)
            this.academicList.push(result.data)
          } else {
            this.academicList.push(result.data)
          }
        } else {
          this.academicList.push(result.data)
        }

        this.academicData = Object.assign({},
          {
            education_level_id: 0,
            score_system_id: 0,
            class_or_grade_id: 0,
            degree_title: '',
            degree_title_bn: '',
            group: '',
            group_bn: '',
            institute: '',
            institute_bn: '',
            address: '',
            address_bn: '',
            year_from: '',
            year_to: '',
            passing_year: '',
            attachment: null
          }
        )
      } else {
        this.$refs.form.setErrors(result.errors)
        this.$toast.error({
          title: 'Error',
          message: 'Operation failed! Please, try again.'
        })
      }
    },
    academicSubmit () {
      if (this.academicList.length > 0) {
        return { success: 1 }
      } else {
        this.$toast.error({
          title: 'Error',
          message: 'Please Fill the form'
        })
      }
    },
    onAttachmentChange (e) {
      this.tmpAttachment = e.target.files[0]
    },
    edit (item) {
      this.academicData = item
    },
    remove (item, key) {
      this.$swal({
        title: this.$t('globalTrans.deleteConfirmMsg'),
        showCancelButton: true,
        confirmButtonText: this.$t('globalTrans.yes'),
        cancelButtonText: this.$t('globalTrans.no'),
        focusConfirm: false
      }).then((result) => {
        if (result.isConfirmed) {
          this.destroy(item.id, key)
        }
      })
    },
    destroy (itemId, key) {
      RestApi.deleteData(incentiveGrantServiceBaseUrl, `${academicDestroy}/${itemId}`).then(response => {
        if (response.success) {
          this.$toast.success({
            title: 'Success',
            message: 'Data Deleted Successfully',
            color: '#D6E09B'
          })
          this.academicList.splice(key, 1)
        } else {
          this.$toast.error({
            title: 'Error',
            message: 'Operation failed! Please, try again.'
          })
        }
      })
    },
    getClassGradeList (educationLevelId, scoreTypeId) {
      if (scoreTypeId === 1) {
        if (educationLevelId > 2) {
          return this.classList
        } else {
          return this.divisionList
        }
      } else {
        return this.gradeList
      }
    }
  }
}
</script>

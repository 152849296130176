<template>
  <b-container fluid>
    <b-overlay :show="loading">
      <b-row>
        <b-col sm="12">
          <b-card no-body>
            <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
              <b-form  @submit.prevent="handleSubmit(generalSubmit)" @reset.prevent="reset" >
                <iq-card>
                  <template v-slot:headerTitle>
                    <div class="text-center">
                      <h5 class="card-title">{{ $t('admission_form.gen_inf') }}</h5>
                    </div>
                  </template>
                  <template v-slot:body>
                    <b-row>
                      <b-col sm="4">
                        <ValidationProvider name="Fiscal Year" vid="fiscal_year_id" rules="required|min_value:1">
                          <b-form-group class="row" label-cols-sm="12" label-for="fiscal_year_id" slot-scope="{ valid, errors }">
                            <template v-slot:label>
                              {{$t('warehouseFarmer.fiscal_year')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                                plain
                                v-model="main_form.fiscal_year_id"
                                :options="fiscalYearList"
                                id="fiscal_year_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col msm="4">
                        <ValidationProvider name="Circular Memo" vid="circular_id" rules="required|min_value:1">
                          <b-form-group class="row" label-cols-sm="12" label-for="circular_id" slot-scope="{ valid, errors }">
                            <template v-slot:label>
                              {{$t('admission_form.circular_memo')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                                plain
                                v-model="main_form.circular_id"
                                :options="circularList"
                                id="circular_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                disabled
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col sm="4">
                        <ValidationProvider name="Candidate Type" vid="candidate_type" rules="required|min_value:1">
                          <b-form-group class="row" label-cols-sm="12" label-for="candidate_type" slot-scope="{ valid, errors }">
                            <template v-slot:label>
                              {{ $t('admission_form.candidate_type')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                                plain
                                v-model="main_form.candidate_type"
                                :options="candidateTypeList"
                                id="candidate_type"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col md="4">
                        <ValidationProvider name="Candidate Organization Type" vid="can_org_type" rules="required|min_value:1">
                          <b-form-group class="row" label-cols-sm="12" label-for="can_org_type" slot-scope="{ valid, errors }">
                            <template v-slot:label>
                              {{$t('admission_form.candidate_org')}}
                            </template>
                            <b-form-select
                                plain
                                v-model="main_form.can_org_type"
                                :options="candidateOrgTypeList"
                                @change="getOrganization"
                                id="candidate_org"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col md="4">
                          <ValidationProvider name="University/Board" vid="university_id" rules="required|min_value:1">
                            <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="university_id"
                            slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                    {{ $t('grantDistribution.university_name')}}
                                </template>
                                <b-form-select
                                  plain
                                  v-model="main_form.university_id"
                                  :options="enlistedUniversityList"
                                  id="university_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                      </b-col>
                      <b-col md="4">
                        <ValidationProvider name="Field Study (En)" vid="field_of_study" rules="required">
                          <b-form-group class="row" label-cols-sm="12" label-for="field_of_study" slot-scope="{ valid, errors }">
                            <template v-slot:label>
                              {{$t('admission_form.field_study_en')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                            type="text"
                            id="field_of_study"
                            v-model="main_form.field_of_study"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col md="4">
                        <ValidationProvider name="Field Study (Bn)" vid="field_of_study_bn" rules="required">
                          <b-form-group class="row" label-cols-sm="12" label-for="field_of_study_bn" slot-scope="{ valid, errors }">
                            <template v-slot:label>
                              {{$t('admission_form.field_study_bn')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                            type="text"
                            id="field_of_study_bn"
                            v-model="main_form.field_of_study_bn"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col md="4">
                        <ValidationProvider name="Name (En)" vid="name" rules="required">
                          <b-form-group class="row" label-cols-sm="12" label-for="name" slot-scope="{ valid, errors }">
                            <template v-slot:label>
                              {{$t('admission_form.full_name_en')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                            type="text"
                            id="name"
                            v-model="main_form.name"
                            disabled
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col md="4">
                        <ValidationProvider name="Full Name (Bn)" vid="name_bn" rules="required">
                          <b-form-group class="row" label-cols-sm="12" label-for="name_bn" slot-scope="{ valid, errors }">
                            <template v-slot:label>
                              {{$t('admission_form.full_name_bn')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                            type="text"
                            id="name_bn"
                            v-model="main_form.name_bn"
                            disabled
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col md="4">
                        <ValidationProvider name="Father Name (En)" vid="father_name" rules="required">
                          <b-form-group class="row" label-cols-sm="12" label-for="father_name" slot-scope="{ valid, errors }">
                            <template v-slot:label>
                              {{$t('admission_form.father_name_en')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                            type="text"
                            id="father_name"
                            v-model="main_form.father_name"
                            disabled
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col md="4">
                        <ValidationProvider name="Father Name (Bn)" vid="father_name_bn" rules="required">
                          <b-form-group class="row" label-cols-sm="12" label-for="father_name_bn" slot-scope="{ valid, errors }">
                            <template v-slot:label>
                              {{ $t('admission_form.father_name_bn')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              type="text"
                              id="father_name_bn"
                              disabled
                              v-model="main_form.father_name_bn"
                              :state="errors[0] ? false : (valid ? true : null)"
                            >
                            </b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col md="4">
                        <ValidationProvider name="Mother Name (En)" vid="mother_name" rules="required">
                          <b-form-group class="row" label-cols-sm="12" label-for="mother_name" slot-scope="{ valid, errors }">
                            <template v-slot:label>
                              {{$t('admission_form.mother_name_en')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                            type="text"
                            id="mother_name"
                            v-model="main_form.mother_name"
                            disabled
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col md="4">
                        <ValidationProvider name="Mother Name (Bn)" vid="mother_name_bn" rules="required">
                          <b-form-group class="row" label-cols-sm="12" label-for="mother_name_bn" slot-scope="{ valid, errors }">
                            <template v-slot:label>
                              {{$t('admission_form.mother_name_bn')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                            type="text"
                            id="mother_name_bn"
                            v-model="main_form.mother_name_bn"
                            disabled
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col md="4">
                        <ValidationProvider name="Date of Birth" vid="date_of_birth" rules="required">
                          <b-form-group class="row" label-cols-sm="12" label-for="date_of_birth" slot-scope="{ valid, errors }">
                            <template v-slot:label>
                              {{ $t('externalUserIrrigation.date_of_birth') }} <span class="text-danger">*</span>
                            </template>
                              <b-form-input
                                class="date-picker"
                                v-model="main_form.date_of_birth"
                                :placeholder="$t('globalTrans.select_date')"
                                :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col md="4">
                        <ValidationProvider name="Age" vid="age" rules="required">
                          <b-form-group class="row" label-cols-sm="12" label-for="age" slot-scope="{ valid, errors }">
                            <template v-slot:label>
                              {{$t('admission_form.age')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              v-model="main_form.age"
                              id="age"
                              :state="errors[0] ? false : (valid ? true : null)"
                              disabled
                            >
                            </b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col md="4">
                        <ValidationProvider name="Place of Birth" vid="place_of_birth" rules="required">
                          <b-form-group class="row" label-cols-sm="12" label-for="place_of_birth" slot-scope="{ valid, errors }">
                            <template v-slot:label>
                              {{$t('admission_form.place_of_birth')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                            type="text"
                            id="place_of_birth"
                            v-model="main_form.place_of_birth"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col md="4">
                        <ValidationProvider name="Marital Status" vid="marital_status" rules="required|min_value:1">
                          <b-form-group class="row" label-cols-sm="12" label-for="marital_status" slot-scope="{ valid, errors }">
                            <template v-slot:label>
                              {{$t('admission_form.mat_status')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                                plain
                                v-model="main_form.marital_status"
                                :options="maritalStatusList"
                                id="marital_status"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col md="4">
                        <ValidationProvider name="NID" vid="nid" rules="required">
                          <b-form-group class="row" label-cols-sm="12" label-for="nid" slot-scope="{ valid, errors }">
                            <template v-slot:label>
                              {{$t('admission_form.nid_no')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              id="nid"
                              v-model="main_form.nid"
                              disabled
                              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col md="4">
                        <ValidationProvider name="Mobile No" vid="mobile_no" rules="required">
                          <b-form-group class="row" label-cols-sm="12" label-for="mobile_no" slot-scope="{ valid, errors }">
                            <template v-slot:label>
                              {{$t('admission_form.mobile_no')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              id="mobile_no"
                              v-model="main_form.mobile_no"
                              disabled
                              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col md="4">
                        <ValidationProvider name="Email" vid="email" rules="required">
                          <b-form-group class="row" label-cols-sm="12" label-for="email" slot-scope="{ valid, errors }">
                            <template v-slot:label>
                              {{$t('admission_form.email')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                            type="email"
                            id="email"
                            v-model="main_form.email"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col md="4">
                        <ValidationProvider name="Organization" vid="can_org_id" rules="required|min_value:1">
                          <b-form-group class="row" label-cols-sm="12" label-for="can_org_id" slot-scope="{ valid, errors }">
                            <template v-slot:label>
                              {{$t('externalUserIrrigation.organization')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                                plain
                                v-model="main_form.can_org_id"
                                :options="orgList"
                                id="pre_division_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col md="4">
                        <ValidationProvider name="Organization Address" vid="org_add">
                          <b-form-group class="row" label-cols-sm="12" label-for="org_add" slot-scope="{ valid, errors }">
                            <template v-slot:label>
                              {{$t('admission_form.org_add')}}
                            </template>
                            <b-form-input
                            type="text"
                            id="org_add"
                            v-model="orgName"
                            :state="errors[0] ? false : (valid ? true : null)"
                            disabled
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col md="4">
                        <ValidationProvider name="Designation (En)" vid="designation" rules="required">
                          <b-form-group class="row" label-cols-sm="12" label-for="designation" slot-scope="{ valid, errors }">
                            <template v-slot:label>
                              {{ $t('admission_form.design_en')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                            type="text"
                            id="designation"
                            v-model="main_form.designation"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col md="4">
                        <ValidationProvider name="Designation (Bn)" vid="designation_bn" rules="required">
                          <b-form-group class="row" label-cols-sm="12" label-for="designation_bn" slot-scope="{ valid, errors }">
                            <template v-slot:label>
                              {{$t('admission_form.design_bn')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                            type="text"
                            id="designation_bn"
                            v-model="main_form.designation_bn"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col md="4">
                        <ValidationProvider name="Photo">
                          <b-form-group class="row" label-cols-sm="12" label-for="photo" slot-scope="{ errors }">
                            <template v-slot:label>
                              {{ $t('admission_form.photo') }}
                              <v-slot v-if="main_form.photo !== null">
                                <a :href="baseUrl + 'download-attachment?file=uploads/general-information/photo/original/' + main_form.photo" title="Photo" class="mr-3"><i class="ri-download-cloud-fill"></i></a>
                              </v-slot>
                            </template>
                            <b-form-file
                              id="photo"
                              @change="onPhotoChange"
                              >
                            </b-form-file>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col md="4">
                        <ValidationProvider name="Signature" vid="signature">
                          <b-form-group class="row" label-cols-sm="12" label-for="signature" slot-scope="{ valid, errors }">
                            <template v-slot:label>
                              {{ $t('admission_form.signature') }}
                              <v-slot v-if="main_form.signature !== null">
                                <a :href="baseUrl + 'download-attachment?file=uploads/general-information/signature/original/' + main_form.signature" title="Signature" class="mr-3"><i class="ri-download-cloud-fill"></i></a>
                              </v-slot>
                            </template>
                            <b-form-file
                              id="signature"
                              @change="onSignatureChange"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                            </b-form-file>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                    </b-row>
                  </template>
                </iq-card>
              </b-form>
            </ValidationObserver>
          </b-card>
        </b-col>
      </b-row>
  </b-overlay>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { genInfoStore } from '../../../api/routes'
import flatpickr from 'flatpickr'

export default {
  props: ['main_form'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
      loading: false,
      baseUrl: incentiveGrantServiceBaseUrl,
      tmpSignature: [],
      tmpPhoto: [],
      orgName: '',
      candidateType: [
        { value: 1, text: 'Principle', text_en: 'Principle', text_bn: 'মুখ্য' },
        { value: 2, text: 'Alternative', text_en: 'Alternative', text_bn: 'বিকল্প' }
      ],
      candidateOrg: [
        { value: 1, text: 'Under Ministry', text_en: 'Under Ministry', text_bn: 'মন্ত্রণালয়ের অধীনে' },
        { value: 2, text: 'Others', text_en: 'Others', text_bn: 'অন্যান্য' }
      ],
      maritalStatus: [
        { value: 1, text: 'Single', text_en: 'Single', text_bn: 'অবিবাহিত' },
        { value: 2, text: 'Married', text_en: 'Married', text_bn: 'বিবাহিত' }
      ],
      orgList: [],
      errors: []
    }
  },
  created () {
    if (this.$route.query.id) {
      this.OrgList = this.getOrganization(this.main_form.can_org_type)
      this.orgName = this.getOrgAddress(this.main_form.can_org_id)
    }
  },
  watch: {
    'main_form.date_of_birth': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.ageCalculation()
      }
    },
    'main_form.can_org_type': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.OrgList = this.getOrganization(newVal)
      }
    },
    'main_form.can_org_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.orgName = this.getOrgAddress(newVal)
      }
    },
    currentLocale: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.localizeAllDropdown(newVal)
      }
    }
  },
  mounted () {
    flatpickr('.date-picker', {})
    core.index()
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    },
    circularList: function () {
      const circularList = this.$store.state.ExternalUserIrrigation.incentiveGrant.circularInfoList.filter(item => item.status === 1)
      return circularList.map(item => {
        return Object.assign({}, item, { value: item.value, text: item.memo_no })
      })
    },
    maritalStatusList: function () {
      return this.maritalStatus.map(obj => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.value, text: obj.text_bn }
        } else {
          return { value: obj.value, text: obj.text_en }
        }
      })
    },
    candidateOrgTypeList: function () {
      return this.candidateOrg.map(obj => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.value, text: obj.text_bn }
        } else {
          return { value: obj.value, text: obj.text_en }
        }
      })
    },
    fiscalYearList: function () {
      return this.$store.state.ExternalUserIrrigation.commonObj.fiscalYearList
    },
    candidateTypeList: function () {
      return this.candidateType.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
        } else {
          return { value: item.value, text: item.text_en }
        }
      })
    },
    enlistedUniversityList: function () {
      const universityList = this.$store.state.ExternalIncentiveFarmer.incentiveFarmerObj.enlistedUniversityList.filter(item => item.status === 1)
      return universityList.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
        } else {
          return { value: item.value, text: item.text_en }
        }
      })
    }
  },
  methods: {
    ageCalculation () {
      const today = new Date()
      const birthDate = new Date(this.main_form.date_of_birth)
      this.main_form.age = today.getFullYear() - birthDate.getFullYear()
      const m = today.getMonth() - birthDate.getMonth()
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        this.main_form.age--
      }
    },
    getOrganization (orgType) {
      if (orgType === 2) {
        const circularList = this.$store.state.ExternalUserIrrigation.incentiveGrant.narseInstituteList.filter(item => item.status === 1)
        this.orgList = circularList.map(item => {
          return Object.assign({}, item, { value: item.value, text: this.currentLocale === 'bn' ? item.text_bn : item.text_en })
        })
      } else {
        const organizations = this.$store.state.ExternalUserIrrigation.incentiveGrant.orgList
        this.orgList = organizations.map(item => {
          return Object.assign({}, item, { value: item.value, text: this.currentLocale === 'bn' ? item.text_bn : item.text_en })
        })
      }
    },
    getOrgAddress (orgId) {
      if (this.main_form.can_org_type === 2) {
        const organizations = this.$store.state.ExternalUserIrrigation.incentiveGrant.narseInstituteList.filter(item => item.status === 1)
        const org = organizations.find(org => org.value === orgId)
        return this.$i18n.locale === 'bn' ? org.institute_address_bn : org.institute_address
      } else {
        const organizations = this.$store.state.ExternalUserIrrigation.incentiveGrant.orgList
        const org = organizations.find(org => org.value === orgId)
        return this.$i18n.locale === 'bn' ? org.address_bn : org.address
      }
    },
    async generalSubmit () {
      this.loading = true
      let mainForm = ''
      if (this.$route.query.id) {
        mainForm = Object.assign({}, this.main_form, {
          applicant_id: this.$store.state.Auth.authUser.id,
          id: this.$route.query.id
        })
      } else {
        const appGenInfoId = this.$store.state.ExternalUserIrrigation.incentiveGrant.applicantdetails ? this.$store.state.externaluserirrigation.incentivegrant.applicantdetails.id : 0
        mainForm = Object.assign({}, this.main_form, {
          applicant_id: this.$store.state.Auth.authUser.id,
          id: appGenInfoId !== 0 ? appGenInfoId : this.$store.state.ExternalUserIrrigation.incentiveGrant.appGeneralInfoID
        })
      }

      var formData = new FormData()
      Object.keys(mainForm).map(key => {
        if (key === 'photo') {
          formData.append(key, this.tmpPhoto)
        } else if (key === 'signature') {
          formData.append(key, this.tmpSignature)
        } else {
          formData.append(key, mainForm[key])
        }
      })

      let result = null
      result = await RestApi.postData(incentiveGrantServiceBaseUrl, genInfoStore, formData)
      this.loading = false
      if (result.success) {
        this.$toast.success({
          title: 'Success',
          message: this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        this.orgList.push({ value: -1, text: this.$i18n.locale === 'bn' ? 'অন্য' : 'Other' })
        const tmpResult = Object.assign(result, { customOrgList: this.orgList })
        return tmpResult
      } else {
        this.$toast.error({
          title: 'Error',
          message: this.$t('globalTrans.form_error_msg'),
          color: '#D6E09B'
        })
        this.$refs.form.setErrors(result.errors)
      }
    },
    onPhotoChange (e) {
      this.tmpPhoto = e.target.files[0]
    },
    onSignatureChange (e) {
      this.tmpSignature = e.target.files[0]
    },
    localizeAllDropdown (locale) {
      this.candidateType = this.candidateType.map(obj => {
        return Object.assign({}, obj, {
          text: locale === 'en' ? obj.text_en : obj.text_bn
        })
      })

      this.candidateOrg = this.candidateOrg.map(obj => {
        return Object.assign({}, obj, {
          text: locale === 'en' ? obj.text_en : obj.text_bn
        })
      })

      this.maritalStatus = this.maritalStatus.map(obj => {
        return Object.assign({}, obj, {
          text: locale === 'en' ? obj.text_en : obj.text_bn
        })
      })
    }
  }
}
</script>
<style scoped>
 .form-control {
  padding: 0.375rem 0.75rem
 }
</style>

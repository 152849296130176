<template>
  <b-container fluid>
    <b-overlay :show="loading">
      <b-row>
        <b-col sm="12">
          <b-card no-body>
            <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
              <b-form @submit.prevent="handleSubmit(annexure2RelevantFactSubmit)" @reset.prevent="reset">
                <iq-card>
                  <template v-slot:headerTitle>
                    <div class="text-center">
                      <h5 class="card-title">{{ $t('admission_form.annexure_2_relevant_fact') }}</h5>
                    </div>
                  </template>
                  <template v-slot:body>
                    <b-row>
                      <b-col sm="6">
                        <ValidationProvider name="Fact">
                          <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="fact"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('admission_form.fact_en') }}
                            </template>
                            <b-form-textarea
                              plain
                              v-model="relevant_info.fact"
                              id="fact"
                              :state="errors[0] ? false : (valid ? true : null)"
                            >
                            </b-form-textarea>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col sm="6">
                        <ValidationProvider name="Fact (Bn)" vid="fact_bn">
                          <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="fact_bn"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('admission_form.fact_bn') }}
                            </template>
                            <b-form-textarea
                              plain
                              v-model="relevant_info.fact_bn"
                              id="fact"
                              :state="errors[0] ? false : (valid ? true : null)"
                            >
                            </b-form-textarea>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                    </b-row>
                  </template>
                </iq-card>
              </b-form>
            </ValidationObserver>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { RelevantFactStore } from '../../../api/routes'

export default {
  props: ['relevant_info'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
      loading: false
    }
  },
  created () {
    if (this.relevant_info === null) {
      this.relevant_info = Object.assign({}, {
        fact: '',
        fact_bn: ''
      })
    }
  },
  mounted () {
    core.index()
  },
  methods: {
    async annexure2RelevantFactSubmit () {
      this.loading = true
      let result = null
      const generalInfoID = this.$store.state.ExternalUserIrrigation.incentiveGrant.applicantDetails.id
      const formData = Object.assign({}, this.relevant_info, { app_general_info_id: generalInfoID })
      result = await RestApi.postData(incentiveGrantServiceBaseUrl, RelevantFactStore, formData)

      this.loading = false
      if (result.success) {
        this.$toast.success({
          title: 'Success',
          message: this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        return result
      } else {
        this.$toast.error({
          title: 'Error',
          message: this.$t('globalTrans.form_error_msg'),
          color: '#D6E09B'
        })
        this.$refs.form.setErrors(result.errors)
      }
    }
  }
}
</script>

<template>
  <b-container fluid>
    <b-overlay :show="loading">
      <b-row>
        <b-col sm="12">
          <b-card no-body>
            <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
              <b-form  @submit.prevent="handleSubmit(save)" @reset.prevent="reset" >
                <iq-card>
                  <template v-slot:headerTitle>
                    <div class="text-center">
                      <h5 class="card-title">{{ $t('admission_form.seminar') }}</h5>
                    </div>
                  </template>
                  <template v-slot:body>
                    <b-row>
                      <b-col lg="3" sm="8">
                        <ValidationProvider name="Seminar Name (En)" vid="seminar_name">
                          <b-form-group
                            label-for="seminar_name"
                            slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                            {{ $t('admission_form.semi_name_en')}}
                          </template>
                          <b-form-input
                            id="committee_name"
                            v-model="seminarData.seminar_name"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="3">
                        <ValidationProvider name="Seminar Name (Bn)" vid="seminar_name_bn">
                          <b-form-group
                            label-for="seminar_name_bn"
                            slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                            {{ $t('admission_form.semi_name_bn')}}
                          </template>
                          <b-form-input
                            id="committee_name"
                            v-model="seminarData.seminar_name_bn"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="3">
                        <ValidationProvider name="Seminar Venu (En)" vid="semi_v_en">
                          <b-form-group
                            label-for="batch"
                            slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                            {{ $t('admission_form.semi_v_en')}}
                          </template>
                            <b-form-input
                            id="committee_name"
                            v-model="seminarData.venue"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="3" sm="8">
                        <ValidationProvider name="Seminar Venu (Bn)" vid="semi_v_bn">
                          <b-form-group
                            label-for="venue_bn"
                            slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                            {{ $t('admission_form.semi_v_bn')}}
                          </template>
                          <b-form-input
                            id="committee_name"
                            v-model="seminarData.venue_bn"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="3">
                        <ValidationProvider name="Start Date" vid="date_from">
                          <b-form-group
                            label-for="from_date"
                            slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                            {{ $t('birtan.start_date')}}
                          </template>
                          <b-form-input
                            class="date-picker"
                            v-model="seminarData.from_date"
                            :placeholder="$t('globalTrans.select_date')"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="3">
                        <ValidationProvider name="End Date" vid="to_date">
                          <b-form-group
                            label-for="to_date"
                            slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                            {{ $t('birtan.end_date')}}
                          </template>
                          <b-form-input
                            class="date-picker active"
                            v-model="seminarData.to_date"
                            :placeholder="$t('globalTrans.select_date')"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                              {{ errors[0] }}
                          </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="3" sm="6">
                        <div style="margin-top:35px;">
                          <b-button type="submit" variant="primary" class="mr-2">{{ $t('admission_form.add') }}</b-button>
                        </div>
                      </b-col>
                    </b-row>
                  </template>
                </iq-card>
              </b-form>
            </ValidationObserver>
            <template v-if="seminarList.length > 0">
              <b-row>
                <b-col lg="12" sm="12" class="table-responsive">
                  <b-table :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="seminarList" :fields="columns">
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + 1) }}
                    </template>
                    <template v-slot:cell(from_date)="data">
                      {{ data.item.from_date | dateFormat }}
                    </template>
                    <template v-slot:cell(to_date)="data">
                      {{ data.item.to_date | dateFormat }}
                    </template>
                    <template v-slot:cell(action)="data">
                      <b-button title="Edit" v-b-modal.modal-4 variant=" iq-bg-success mr-1" size="sm" @click="edit(data.item)"><i class="ri-ball-pen-fill m-0"></i></b-button>
                      <b-button title="Delete" variant=" iq-bg-danger" size="sm" @click="remove(data.item, data.index)"><i class="ri-delete-bin-line m-0"></i></b-button>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
            </template>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { seminarStore, seminarDestroy } from '../../../api/routes'
import flatpickr from 'flatpickr'

export default {
  props: ['seminar_info'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
      loading: false,
      seminarList: [],
      seminarData: {
        seminar_name: '',
        seminar_name_bn: '',
        venue: '',
        venue_bn: '',
        from_date: '',
        to_date: ''
      }
    }
  },
  computed: {
    columns () {
      const labels = [
        { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
        { label: this.$t('admission_form.semi_name'), class: 'text-left' },
        { label: this.$t('admission_form.semi_v'), class: 'text-left' },
        { label: this.$t('globalTrans.start_date'), class: 'text-left' },
        { label: this.$t('globalTrans.end_date'), class: 'text-left' },
        { label: this.$t('globalTrans.action'), class: 'text-center' }
      ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'seminar_name_bn' },
          { key: 'venue_bn' },
          { key: 'from_date' },
          { key: 'to_date' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'seminar_name' },
          { key: 'venue' },
          { key: 'from_date' },
          { key: 'to_date' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    }
  },
  created () {
    if ((this.seminar_info.length > 0) && (this.seminar_info[0].seminar_name !== '')) {
      this.seminarList = this.seminar_info
    }
  },
  mounted () {
    flatpickr('.date-picker', {})
    core.index()
  },
  methods: {
    async save () {
      this.loading = true
      let result = null
      const generalInfoID = this.$store.state.ExternalUserIrrigation.incentiveGrant.applicantDetails.id
      const formData = Object.assign({}, this.seminarData, { app_general_info_id: generalInfoID })
      result = await RestApi.postData(incentiveGrantServiceBaseUrl, seminarStore, formData)

      this.loading = false

      if (result.success) {
        this.$toast.success({
          title: this.$t('globalTrans.success'),
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        this.$nextTick(() => {
          this.$refs.form.reset()
        })
        if (this.seminar_info.length > 0 && this.seminarData.seminar_name !== '') {
          const seminarExist = this.seminarList.find(seminar => seminar.id === result.data.id)
          if (typeof seminarExist !== 'undefined') {
            this.seminarList = this.seminarList.filter(seminar => seminar.id !== seminarExist.id)
            this.seminarList.push(result.data)
          } else {
            this.seminarList.push(result.data)
          }
        } else {
          this.seminarList.push(result.data)
        }

        this.seminarData = Object.assign({},
          {
            seminar_name: '',
            seminar_name_bn: '',
            venue: '',
            venue_bn: '',
            from_date: '',
            to_date: ''
          }
        )
      } else {
        this.$refs.form.setErrors(result.errors)
        this.$toast.error({
          title: 'Error',
          message: 'Operation failed! Please, try again.'
        })
      }
    },
    seminarSubmit () {
      return { success: 1 }
    },
    edit (item) {
      this.seminarData = item
    },
    remove (item, key) {
      this.$swal({
        title: this.$t('globalTrans.deleteConfirmMsg'),
        showCancelButton: true,
        confirmButtonText: this.$t('globalTrans.yes'),
        cancelButtonText: this.$t('globalTrans.no'),
        focusConfirm: false
      }).then((result) => {
        if (result.isConfirmed) {
          this.destroy(item.id, key)
        }
      })
    },
    destroy (itemId, key) {
      RestApi.deleteData(incentiveGrantServiceBaseUrl, `${seminarDestroy}/${itemId}`).then(response => {
        if (response.success) {
          this.$toast.success({
            title: 'Success',
            message: 'Data Deleted Successfully',
            color: '#D6E09B'
          })
          this.seminarList.splice(key, 1)
        } else {
          this.$toast.error({
            title: 'Error',
            message: 'Operation failed! Please, try again.'
          })
        }
      })
    }
  }
}
</script>
